import { Vue, Options } from 'vue-class-component';
import { useCookies } from "vue3-cookies";

@Options(
{
})


export default class CookieBanner extends Vue 
{
    private accepted : boolean = false;
    private showFooter : boolean = false;
    private showBanner : boolean = false;
    private cookies : any | undefined;

    public async mounted()
    {
        this.cookies = await useCookies().cookies;
        setTimeout(this.checkCookie, 2000);
    }
    
    private checkCookie()
    {
        if (!this.cookies.get('accept'))
        {
            this.showFooter = true;
        }
    }

    private onReviewClick()
    {
        this.showBanner = true;
    }

    private onAcceptClick()
    {
        this.cookies.set('accept', 'true');
        this.$router.go(0);
        //this.showBanner = false;
        //this.showFooter = false;
    }

    private onRejectClick()
    {
        this.cookies.set('accept', 'false');
        this.$router.go(0);
    }

    private onClose()
    {
        this.showBanner = false;
    }
}

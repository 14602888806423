import { Vue, Options } from 'vue-class-component';
import { Check, Close, Search } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';
import QuestionnaireItem from '@/components/QuestionnaireItem/QuestionnaireItem.vue';
import ReportTable from '@/components/ReportTable/ReportTable.vue';
import AnswersByAttribute from '@/components/AnswersByAttribute/AnswersByAttribute.vue';
import SamplePie from '@/components/SamplePie/SamplePie.vue';
import { DragCol, DragRow, ResizeCol, ResizeRow, Resize} from "vue-resizer";

interface ItemModel
{
    questionnaireid: number;
    name: string;
}

@Options(
{
    components:
    {
        NavBarTop,
        QuestionnaireItem,
        ReportTable,
        AnswersByAttribute,
        SamplePie,
        Check,
        Close,
        Search,
        DragCol
    },
})

export default class Home extends Vue 
{
    private loading : boolean = true;
    private hasPermission: boolean | undefined = false;
    private filter : FilterModel = {} as FilterModel;
    private categoryModelList : CategoryModel[] | undefined = [] as CategoryModel[];
    private departmentModelList : DepartmentModel[] | undefined = [] as DepartmentModel[];
    private locationModelList : LocationModel[] | undefined = [] as LocationModel[];
    private customerModelList : CustomerModel[] | undefined = [] as CustomerModel[];
    private questionModelList : QuestionModel[] | undefined = [] as QuestionModel[];
    private questionnaireModelList : QuestionnaireModel[] | undefined = [] as QuestionnaireModel[];
    private questViewModelList : QuestionnaireView[] | undefined = [] as QuestionnaireView[];
    private attributeModelList : AttributeModel[] | undefined = [] as AttributeModel[];
    private reportRefresh : Function | undefined;
    private reportAnswersRefresh : Function | undefined;
    private showChart : boolean = false;

    public async mounted()
    {
        // get permission
        //this.hasPermission = await AuthService.hasPermission("4");
		this.hasPermission = true;

        // load questionnaires
        await this.loadCategories();
        await this.loadDepartments();
        await this.loadLocations();
        await this.loadCustomers();
        await this.loadQuestionnaires();
        await this.loadAttributes();
        await this.loadQuestions();

        // set loading indicator
        this.loading = false;
    }

    private async loadQuestionnaires()
    {
        this.questionnaireModelList = await ApiService.getAllQuestionnaireByFilter(this.filter);
    }

    private async loadCustomers()
    {
        this.customerModelList = await ApiService.getAllCustomerByFilter(this.filter);
    }

    private async loadCategories()
    {
        this.categoryModelList = await ApiService.getAllCategoryByFilter(this.filter);
    }

    private async loadDepartments()
    {
        this.departmentModelList = await ApiService.getAllDepartmentByFilter(this.filter);
    }

    private async loadLocations()
    {
        this.locationModelList = await ApiService.getAllLocationByFilter(this.filter);
    }

    private async loadAttributes()
    {
        this.attributeModelList = await ApiService.getAllAttributeByFilter(this.filter);
    }
    
    private async loadQuestions()
    {
        this.questionModelList = await ApiService.getAllQuestionByFilter(this.filter);
    }

    private async loadReport()
    {
        this.questViewModelList = await ApiService.getReportView(this.filter);
    }

    private onReportClick(model : QuestionnaireModel)
    {
        this.$router.push(`/report/${model.questionnaireid.toString()}`);
    }

    public onReportReady(callback : Function)
    {
        this.reportRefresh = callback;

        if (this.reportRefresh)
        {
            this.reportRefresh();
        }
    }

    public onAnswerReportReady(callback : Function)
    {
        this.reportAnswersRefresh = callback;

        if (this.reportAnswersRefresh)
        {
            this.reportAnswersRefresh();
        }
    }

    private async onFilterChange()
    {
        this.loading = true;

        this.customerModelList = await ApiService.getAllCustomerByFilter(this.filter);
        this.locationModelList = await ApiService.getAllLocationByFilter(this.filter);
        this.departmentModelList = await ApiService.getAllDepartmentByFilter(this.filter);
        this.categoryModelList = await ApiService.getAllCategoryByFilter(this.filter);
        this.questionnaireModelList = await ApiService.getAllQuestionnaireByFilter(this.filter);
        this.attributeModelList = await ApiService.getAllAttributeByFilter(this.filter);
        this.questionModelList = await ApiService.getAllQuestionByFilter(this.filter);

        this.questViewModelList = await ApiService.getReportView(this.filter);

        if (this.reportRefresh)
        {
            this.reportRefresh();
        }

        if (this.reportAnswersRefresh)
        {
            this.reportAnswersRefresh();
        }

        this.loading = false;
    }

    public onCategoryAll()
    {
        if (this.categoryModelList)
        {
            this.filter.selectedCategories = [];
            for (let category of this.categoryModelList)
            {
                this.filter.selectedCategories.push(category.categoryid);
            }
        }

        this.onFilterChange();
    }

    public onCategoryNone()
    {
        this.filter.selectedCategories = [];
        this.onFilterChange();
    }

    public onCustomerAll()
    {
        if (this.customerModelList)
        {
            this.filter.selectedCustomers = [];
            for (let customer of this.customerModelList)
            {
                this.filter.selectedCustomers.push(customer.customerid);
            }
        }

        this.onFilterChange();
    }

    public onCustomerNone()
    {
        this.filter.selectedCustomers = [];
        this.onFilterChange();
    }

    public onDepartmentAll()
    {
        if (this.departmentModelList)
        {
            this.filter.selectedDepartments = [];
            for (let department of this.departmentModelList)
            {
                this.filter.selectedDepartments.push(department.departmentid);
            }
        }

        this.onFilterChange();
    }

    public onDepartmentNone()
    {
        this.filter.selectedDepartments = [];
        this.onFilterChange();
    }

    public onLocationAll()
    {
        if (this.locationModelList)
        {
            this.filter.selectedLocations = [];
            for (let location of this.locationModelList)
            {
                this.filter.selectedLocations.push(location.locationid);
            }
        }

        this.onFilterChange();
    }

    public onLocationNone()
    {
        this.filter.selectedLocations= [];
        this.onFilterChange();
    }
    
    public onQuestionnaireAll()
    {
        if (this.questionnaireModelList)
        {
            this.filter.selectedQuestionnaires = [];
            for (let quest of this.questionnaireModelList)
            {
                this.filter.selectedQuestionnaires.push(quest.questionnaireid);
            }
        }

        this.onFilterChange();
    }

    public onQuestionnaireNone()
    {
        this.filter.selectedQuestionnaires = [];
        this.onFilterChange();
    }

    public onAttributeAll()
    {
        if (this.attributeModelList)
        {
            this.filter.selectedAttributes = [];
            for (let attribute of this.attributeModelList)
            {
                this.filter.selectedAttributes.push(attribute.attributeid);
            }
        }

        this.onFilterChange();
    }

    public onAttributeNone()
    {
        this.filter.selectedAttributes = [];
        this.onFilterChange();
    }

    public onQuestionAll()
    {
        if (this.questionModelList)
        {
            this.filter.selectedQuestions = [];
            for (let quest of this.questionModelList)
            {
                this.filter.selectedQuestions.push(quest.questionid);
            }
        }

        this.onFilterChange();
    }

    public onQuestionNone()
    {
        this.filter.selectedQuestions = [];
        this.onFilterChange();
    }
}

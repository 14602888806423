import { Vue, Options } from 'vue-class-component';
import { CircleCheck } from '@element-plus/icons-vue';

@Options(
    {
        components:
        {
            CircleCheck
        },
    })

export default class StepDone extends Vue 
{
    private tokenId: string = '';
    private questionnaireId: string = '0';

    public mounted()
    {
        this.tokenId = this.$route.params.tokenid as string;
        this.questionnaireId = this.$route.params.questionnaireid as string;
    }
}

enum Settings
{
    DASH_COLOR_1 = 1,
    DASH_COLOR_2 = 2,
    DASH_COLOR_3 = 3,
    DASH_COLOR_4 = 4,
    DASH_COLOR_5 = 5,
    DASH_COLOR_6 = 6,
    DASH_COLOR_7 = 7,
    DASH_COLOR_8 = 8,
    DASH_COLOR_9 = 9,
    DASH_COLOR_10 = 10
}

export default Settings

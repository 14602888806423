import { Vue, Options } from 'vue-class-component';
import Chart from 'chart.js';
import { Pie, Bar } from 'vue-chartjs';
import ChartService from '@/services/ChartService';
import { Chart as ChartJS, ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(ArcElement, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

interface IDatasetModel
{
    data : number[];
    label : string;
    backgroundColor: string[];
}

interface IChartModel
{
    data : number[];
    labels : string[];
    colors : string[];
    datasets : IDatasetModel[];
}

interface IItemModel
{
    customer: string;
    questionnaire: string;
    num_tickets: number;
    num_participants: number;
    num_done: number;
    color: string | undefined;
}


@Options(
{
    components: 
    {
        Pie,
        Bar
    }
})


export default class SamplePie extends Vue
{
    private chartModel : IChartModel = {} as IChartModel;
    private options : string[] = [] as string[];
	private colors : string[] = ['#105388', '#22ab55'] as string[];
    private showChart : boolean = false;
    
    public async mounted()
    {
        //this.$emit("chart_samplepie_ready", this.refresh)
        
        // set chart options;
        this.options =
        {
            responsive: true,
            maintainAspectRatio: false,
            plugins:
            {
                legend: 
                {
                    display: true,
                    position: 'right',
                }
            }
        };

        // load data and build chart
        await this.buildChart();

        this.showChart = true;
    }
    
    private async buildChart()
    {
        // fetch data
        let list : IItemModel[] | undefined = await ChartService.getSamplePieChart();

        // parse and prepare data
        if (list)
        {
            // reset values
            let dataSet : IDatasetModel = {} as IDatasetModel;
            dataSet.data = [] as number[];
            dataSet.borderColor = [] as string[];
            dataSet.backgroundColor = [] as string[];

            this.chartModel.labels = [] as string[];
            this.chartModel.colors = [] as string[];
            this.chartModel.datasets = [] as IDatasetModel[];
            
            // create data entry for each record
			let i : number = 0;
            for (let item of list)
            {
                this.chartModel.labels.push(`${item.questionnaire} (${item.num_participants})`);
                dataSet.data.push(item.num_participants);
				let color = this.colors[i++];
                dataSet.backgroundColor.push(color);
                dataSet.borderColor.push(ChartService.getBorderColor(color));
            }

            // push the dataset to the list of datasets
            this.chartModel.datasets.push(dataSet);
        }
    }
	

    public async refresh()
    {
        await this.buildChart();
    }
}

import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { UserFilled, Lock } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';

interface PasswordForm
{
    newPassword: string;
    repeatPassword: string;
}

@Options(
{
    components:
    {
        NavBarTop,
        UserFilled,
        Lock
    },
})

export default class UserEdit extends Vue 
{
    private userId: string = '';
    private userName: string = '';
    private activeName: string = "0";
    private loading: boolean = true;
    private loadingPassword : boolean = false;
    private formUserValid: boolean = false;
    private formPasswordValid: boolean = false;
    private hasPermission: boolean | undefined = false;
    private oldPassword: string = '';
    private newPassword: string = '';
    private repeatPassword: string = '';
    private userModel: UserModel | undefined = {} as UserModel;
    private roleModelList : RoleModel[] = [];
    private passwordForm: PasswordForm = {} as PasswordForm;
    private permissions : boolean[] = [] as boolean[];
    private rules =
    {
        email:
        [
            {
                type: 'email',
                required: true,
                message: "Bitte geben Sie eine gültige E-Mail für den Benutzer ein.",
                trigger: "blur"
            }
        ],
        username:
        [
            {
                required: true,
                message: "Bitte geben Sie einen Benutzernamen ein.",
                trigger: "blur"
            }
        ],
        firstname:
        [
            {
                required: true,
                message: "Bitte geben Sie den Vornamen des Benutzers ein.",
                trigger: "blur"
            }
        ],
        lastname:
        [
            {
                required: true,
                message: "Bitte geben Sie den Nachnamen des Benutzers ein.",
                trigger: "blur"
            }
        ],
        newPassword:
        [
            {
                required: true,
                message: "Bitte geben Sie ein neues Passwort ein.",
                trigger: "blur"
            }
        ],
        repeatPassword:
        [
            {
                required: true,
                message: "Bitte wiederholen Sie das neue Passwort.",
                trigger: "blur"
            }
        ]
    };

    public async mounted()
    {
        // get permission
        //this.hasPermission = await AuthService.hasPermission("2");
        this.hasPermission = true;

        // get user id from get request
        this.userId = this.$route.params.userid as string;

        // load user from db
        this.userModel = await ApiService.getUser(this.userId);

        if (!this.userModel) 
        {
            this.$router.push('/user');
            return;
        }
        else
        {
            // check if a password has been set yet
            this.oldPassword = this.userModel.password;

            // get user name for breadcrumbs
            this.userName = `${this.userModel.firstname} ${this.userModel.lastname} (${this.userModel.username})`;

            // check form validity
            this.formUserValid = this.validateUserForm();

            // load available roles
            await this.loadRoles();
        }

        this.loading = false;
    }

    private async loadRoles()
    {
        this.roleModelList = await ApiService.getAllRoles() as RoleModel[];
    }

    private async saveUser(user: UserModel)
    {
        let ok: any = await ApiService.updateUser(user);

        if (ok)
        {
            this.userName = `${user.firstname} ${user.lastname} (${user.username})`;
            ElMessage({message: "Änderungen wurden übernommen.", type: 'success'});
        }
        else
        {
            // or show error message
            ElMessage({message: "Änderungen konnten nicht gespeichert werden.", type: 'error'});
        }
    }

    private async savePassword(user: UserModel)
    {
        this.loadingPassword = true;

        let ok: any = await ApiService.patchUser(user);

        if (ok)
        {
            ElMessage({message: "Das neue Passwort wurde gespeichert.", type: 'success'});
            this.oldPassword = this.userModel!.password;
        }
        else
        {
            // or show error message
            ElMessage({message: "Das neue Passwort konnte nicht gespeichert werden.", type: 'error'});
        }

        this.loadingPassword = false;
    }

    private validateEmail()
    {
        if (!this.userModel || !this.userModel.email || this.userModel.email.length <= 0) return false;

        let re: RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return re.test(this.userModel.email);
    }

    private validateUserForm() : boolean
    {
        if (!this.validateEmail()) return false;

        if (!this.userModel || !this.userModel.username || this.userModel.username.length <= 0) return false;

        if (!this.userModel.firstname || this.userModel.firstname.length <= 0) return false;

        if (!this.userModel.lastname || this.userModel.lastname.length <= 0) return false;

        return true;
    }

    private validatePasswordForm() : boolean
    {
        if (!this.passwordForm.newPassword || this.passwordForm.newPassword.length <= 0) return false;

        if (!this.passwordForm.repeatPassword || this.passwordForm.repeatPassword.length <= 0) return false;

        if (this.passwordForm.repeatPassword.length == this.passwordForm.newPassword.length)
        {
            if (this.passwordForm.repeatPassword !== this.passwordForm.newPassword)
            {
                ElMessage({message: "Die Passwörter stimmen nicht überein. Bitte prüfen Sie die Eingaben.", type: 'error'});
                return false;
            }
        }
        else
        {
            return false;
        }

        return true;
    }

    private onInput()
    {
        this.formUserValid = this.validateUserForm();
    }

    private onPasswordInput()
    {
        this.formPasswordValid = this.validatePasswordForm();
    }

    private onUserSaveClick()
    {
        if (this.userModel)
        {
            this.saveUser(this.userModel);
        }
    }

    private onPasswordSaveClick()
    {
        if (this.userModel)
        {
            this.userModel.password = this.passwordForm.newPassword;
            this.savePassword(this.userModel);
        }
    }
}

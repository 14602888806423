import { Vue, Options } from 'vue-class-component';
import { Slide } from 'vue-burger-menu';
import { ArrowDown } from '@element-plus/icons-vue';
import { useCookies } from "vue3-cookies";
import CookieBanner from '@/components/CookieBanner/CookieBanner.vue';
import AuthService from '@/services/AuthService';

@Options(
{
    props:
    {
        activeMenu: 
        {
            type: String,
            required: false
        }
    },
    components:
    {
        Slide,
        ArrowDown,
        CookieBanner
    },
})

export default class NavBarTop extends Vue 
{
    public activeMenu: string | undefined;

    private username: string = '';
    private userlogin: boolean = false;
    private useDarkMode: boolean = false;
    private cookies : any | undefined;
    private breadcrumbs: object[] = [];
    private activeIndex: string = "1";

    public async mounted()
    {
        this.cookies = await useCookies().cookies;

        this.userlogin = await AuthService.isLoggedIn();

        this.useDarkMode = (useCookies().cookies.get('theme_dark') == 'true');

        if (this.userlogin === true)
        {
            this.username = localStorage.getItem('user')!.toString();
        }
        else
        {
            AuthService.logout();
            this.$router.push('/login');
        }
    }

    public logout()
    {
        // logout user
        AuthService.logout();
        this.userlogin = false;

        // return to login page
        this.$router.push('/login');
    }

    public onThemeChange(mode : any)
    {
        this.cookies.set('theme_dark', this.useDarkMode.toString());
        this.$router.go(0);
    }

    public handleOpen(key: any, keyPath: any) 
    {
        console.log(key, keyPath);
    }

    public handleClose(key: any, keyPath: any) 
    {
        console.log(key, keyPath);
    }

    public handleSelect(key: any, keyPath: any) 
    {
        switch (key)
        {
            case "0":
            case "1":
                this.activeIndex = "1";
                this.$router.push("/");
                break;

            case "2-1":
                this.$router.push("/category");
                break;

            case "2-2":
                this.$router.push("/customer");
                break;
            
            case "2-3":
                this.$router.push("/questionnaire");
                break;

            case "2-4":
                this.$router.push("/attribute");
                break;

            case "3-1":
                this.$router.push("/role");
                break;

            case "3-2":
                this.$router.push("/user");
                break;

            case "3-3":
                this.$router.push("/setting");
                break;

            case "4":
                this.$router.push("/simulation");
                break;

        }
    }
}

import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Menu, Check, Delete, EditPen, CirclePlusFilled, ChatLineRound, Brush, Odometer } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import ApiService from '@/services/ApiService';
import AuthService from '@/services/AuthService';

@Options(
{
    components:
    {
        NavBarTop,
		Menu,
		Check,
		EditPen,
		Delete,
		CirclePlusFilled,
		ChatLineRound,
		Brush,
        Odometer
    },
})

export default class Category extends Vue 
{
    private activeName : string = "1";  
	private loadOptions : boolean = false; 
	private optionFormValid : boolean = false;
    private showOptions : boolean = false;
    private showColorPicker : boolean = false;
	private dragId : number = 0;
    private dragOverId : number = 0;
    private dragLastId : number = 0;
    private dragPositionId : number = 0;
    private deleteMode : boolean = false;
    private categoryModelList : CategoryModel[] = [];
    private tableData: object[] = [];
    private loading : boolean = true;
    private formValid : boolean = false;
    private hasPermission: boolean | undefined = false;
    private toDelete : CategoryModel | undefined;
    private newCategoryModel : CategoryModel = {} as CategoryModel;
	private optionModelList : OptionModel[] = [] as OptionModel[];
    private newOptionModel : OptionModel = {} as OptionModel;
	private currentCategory : CategoryModel | undefined;
	private currentOption : OptionModel | undefined;
    private rules =
    {
        name:
        [
            {
                required: true,
                message: 'Bitte geben Sie eine Bezeichnung ein.',
                trigger: 'blur'
            }
        ]
    };

    public async mounted()
    {
        // get permission
        //this.hasPermission = await AuthService.hasPermission("5");
        this.hasPermission = true;

        this.loadList();
    }

    private async loadList()
    {
        this.loading = true;
        this.categoryModelList = await ApiService.getAllCategories() as CategoryModel[];
        this.loading = false;
    }

    private async createCategory()
    {
        // send new category model via request to API and await
        let category = await ApiService.createCategory(this.newCategoryModel);

        // check if successful
        if (category)
        {
			ElMessage(
            {
               	message: "Neue Kategorie erfolgreich erstellt.",
               	type: 'success',
            });

            this.activeName = "1";
            this.newCategoryModel = {} as CategoryModel;
            this.formValid = false;
            await this.loadList();
        }
        else
        {
            // or show error message
			ElMessage(
            {
               	message: "Beim Erstellen der neuen Kategorie ist ein Fehler aufgetreten.",
               	type: 'error',
            });
        }
    }

    private async updateCategory(category : CategoryModel)
    {
        let ok : any = await ApiService.updateCategory(category);

        if (ok)
        {
			ElMessage(
            {
               	message: "Änderungen wurden übernommen.",
               	type: 'success',
            });
        }
        else
        {
            // or show error message
			ElMessage(
            {
               	message: "Änderungen konnten nicht gespeichert werden.",
               	type: 'error',
            });

        }
    }

    private async deleteCategory(category : CategoryModel)
    {
        let ok = await ApiService.deleteCategory(category);

        if (ok)
        {
			ElMessage(
            {
               	message: "Kategorie wurde gelöscht.",
               	type: 'success',
            });
            await this.loadList();
        }
        else
        {
            // or show error message
			ElMessage(
            {
               	message: "Kategorie konnte nicht gelöscht werden.",
               	type: 'error',
            });
        }
    }


    private validateForm() : boolean
    {
        if (!this.newCategoryModel.name || this.newCategoryModel.name.length <= 0) return false;

        return true;
    }

    private onInput()
    {
        this.formValid = this.validateForm();
    }

    private onCategorySaveClick(category : CategoryModel)
    {
        this.updateCategory(category);
    }

    private onDeleteConfirm(action : string)
    {
        if (this.toDelete && action == 'confirm')
        {
            this.deleteCategory(this.toDelete);
        }
    }

    private onCategoryDeleteClick(category : CategoryModel)
    {
        this.toDelete = category;

        ElMessageBox.confirm('Soll diese Kategorie wirklich gelöscht werden?', 'Warning', 
        {
            type: 'warning',
            title: '',
            callback: this.onDeleteConfirm,
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen'
        });
    }

	private onOptionInput()
    {
        this.optionFormValid = (this.newOptionModel && this.newOptionModel.option.length > 0);
    }

    private onNewOptionClick()
    {
        // create new option by sending request to server
        this.createOption();
    }

    private onNewCategoryClick()
    {
        this.createCategory();
    }

	private async loadCategoryOptions()
    {
        // request options for given question from server
        let list = await ApiService.getAllOptionsForCategory(this.currentCategory!.categoryid.toString());

        if (list)
        {
            this.optionModelList = list;
        }
    }

	private async onCategoryEditClick(category : CategoryModel)
    {
        this.currentCategory = category;
        await this.loadCategoryOptions();
        this.showOptions = true;
    }
   	
	private async createOption()
    {
        this.newOptionModel.categoryid = this.currentCategory!.categoryid;
        this.newOptionModel.position = 1;

        if (this.optionModelList.length > 0)
        {
            this.newOptionModel.position = this.optionModelList[this.optionModelList.length-1].position+1;
        }

        let ok = await ApiService.createOption(this.newOptionModel);

        if (ok)
        {
            await this.loadCategoryOptions();
            this.newOptionModel = {} as OptionModel;
            this.optionFormValid = false;
        }
        else
        {
            // or show error message
			ElMessage({message: "Ausprägung konnte nicht hinzugefügt werden.", type: 'error'});
        }
    }

    private async deleteOption(option : OptionModel)
    {
        let ok = await ApiService.deleteOption(option);

        if (ok)
        {
			ElMessage({message: "Ausprägung wurde gelöscht.", type: 'success'});
            await this.loadCategoryOptions();
        }
        else
        {
            // or show error message
			ElMessage({message: "Ausprägung konnte nicht gelöscht werden.", type: 'error'});
        }
    }

	private async switchOptions(optionSource : OptionModel, optionTarget : OptionModel)
    {
        this.loadOptions = true;

        // get both positions
        let sourcePosition = optionSource.position;
        let targetPosition = optionTarget.position;

        // assign switched positions
        optionSource.position = targetPosition;
        optionTarget.position = sourcePosition;

        // send update request to server
        await ApiService.updateOption(optionSource);
        await ApiService.updateOption(optionTarget);

        // reload options list
        await this.loadCategoryOptions();

        this.dragId = 0;
        this.dragOverId = 0;
        this.dragPositionId = 0;

        this.loadOptions = false;
    }

    private async shiftOption(option : OptionModel)
    {
        this.loadOptions = true;
		
		option.weight = Number(option.weight);
        await ApiService.reorderOption(option);
        await this.loadCategoryOptions();

        this.dragId = 0;
        this.dragOverId = 0;
        this.dragLastId = 0;
        this.dragPositionId = 0;

        this.loadOptions = false;

    } 

    private onDragOver(event : DragEvent)
    {
        event.preventDefault();
    }

   	public onOptionDragStart(option : OptionModel)
    {
        this.dragId = option.optionid;
    }

    private onOptionDragEnd()
    {
        this.dragId = 0;
        this.dragOverId = 0;
    }

    private onOptionDragEnter(option : OptionModel)
    {
        this.dragOverId = option.optionid;
        this.dragPositionId = 0;
    }

    private onOptionDragLeave()
    {
        this.dragOverId = 0;
    }

    private onOptionDrop(targetOption : OptionModel)
    {
        if (this.dragId != this.dragOverId)
        {
            for (const sourceOption of this.optionModelList) 
            {
                if (sourceOption.optionid == this.dragId)
                {
                    this.switchOptions(sourceOption, targetOption);
                    break;
                }
            }
        }
    }

    private onPositionDragEnter(option : OptionModel)
    {
        this.dragPositionId = option.optionid;
        this.dragOverId = 0;
        this.dragLastId = 0;
    }

    private onPositionDragLeave()
    {
        this.dragPositionId = 0;
    }

    private onPositionDrop(targetOption : OptionModel)
    {
        if (this.dragId != this.dragPositionId)
        {
            for (const sourceOption of this.optionModelList) 
            {
                if (sourceOption.optionid == this.dragId)
                {
                    sourceOption.position = targetOption.position;
                    this.shiftOption(sourceOption);
                    break;
                }
            }
        }
    }

    private onLastDragEnter(option : OptionModel)
    {
        this.dragLastId = option.optionid;
        this.dragOverId = 0;
        this.dragPositionId = 0;
    }

    private onLastDragLeave()
    {
        this.dragLastId = 0;
    }

    private onLastDrop()
    {
        if (this.dragId != this.dragPositionId)
        {
            for (const sourceOption of this.optionModelList) 
            {
                if (sourceOption.optionid == this.dragId)
                {
                    sourceOption.position = this.optionModelList[this.optionModelList.length-1].position+1;
                    this.shiftOption(sourceOption);
                    break;
                }
            }
        }
    }

    private onDeleteDragEnter()
    {
        this.deleteMode = true;
    }

    private onDeleteDragLeave()
    {
        this.deleteMode = false;
    }

	private onOptionDelete()
    {
        for (const option of this.optionModelList) 
        {
            if (option.optionid == this.dragId)
            {
                this.deleteOption(option);
                break;
            }
        }

        this.deleteMode = false;
    }
    
	private async onWeightConfirm(ev : any)
    {
		if (this.currentOption && ev.action == "confirm" && ev.value && !isNaN(ev.value.replace(',', '.')))
		{
			let val : number = ev.value.replace(',','.') as number;
			this.currentOption.weight = Number(val);
			await ApiService.updateOption(this.currentOption);
		}
    }

	private onOptionEditClick(option : OptionModel)
	{
		this.currentOption = option;
        
		ElMessageBox.prompt('Gewichtung:', 
        {
            callback: this.onWeightConfirm,
            confirmButtonText: 'Speichern',
            cancelButtonText: 'Abbrechen',
			inputValue: option.weight.toString()
        });
	}

	private async onOptionColorChange(option : OptionModel)
	{
		this.currentOption = option;

        let ok: boolean = await ApiService.updateOption(option);
        
        if (!ok)
        {
			ElMessage({message: "Farbe konnte nicht gesetzt werden.", type: 'error'});
        }
	}
}

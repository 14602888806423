import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Check, Checked, Delete, EditPen, CirclePlusFilled } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop,
        Check,
        Checked,
        EditPen,
        Delete,
        CirclePlusFilled
    },
})

export default class Attribute extends Vue 
{
    private activeName: string = "1";
    private attributeModelList : AttributeModel[] = [] as AttributeModel[];
    private loading : boolean = true;
    private loadChoices : boolean = false;
    private formValid : boolean = false;
    private showChoices : boolean = false;
    private choiceFormValid : boolean = false;
    private hasPermission: boolean | undefined = false;
    private toDelete : AttributeModel | undefined;
    private deleteMode : boolean = false;
    private dragId : number = 0;
    private dragOverId : number = 0;
    private dragLastId : number = 0;
    private dragPositionId : number = 0;
    private newAttributeModel : AttributeModel = {isdefault: true, isrequired: true} as AttributeModel;
    private choiceModelList : ChoiceModel[] = [] as ChoiceModel[];
    private newChoiceModel : ChoiceModel = {} as ChoiceModel;
    private currentAttribute : AttributeModel | undefined;
    private rules =
    {
        name:
        [
            {
                required: true,
                message: 'Bitte geben Sie eine Bezeichnung ein.',
                trigger: 'blur'
            }
        ],
    };

    public async mounted()
    {
        // get permissions
        //this.hasPermission = await AuthService.hasPermission("9");
        this.hasPermission = true;
        
        await this.loadAttributeList();
    }

    private async loadAttributeList()
    {
        this.loading = true;
        this.attributeModelList = await ApiService.getAllAttributes() as AttributeModel[];
        this.loading = false;
    }

    private onChoiceInput()
    {
        this.choiceFormValid = (this.newChoiceModel && this.newChoiceModel.choice.length > 0);
    }

    private async createAttribute()
    {
        // send new model via request to API and await
        let att = await ApiService.createAttribute(this.newAttributeModel);

        // check if successful
        if (att)
        {
			ElMessage(
            {
               	message: "Neue Eigenschaft wurde erfolgreich erstellt.",
               	type: 'success',
            });

            this.activeName = "1";
            this.newAttributeModel = {} as AttributeModel;
            this.formValid = false;
            await this.loadAttributeList();
        }
        else
        {
            // or show error message
			ElMessage({message: "Beim Erstellen der neuen Eigenschaft ist ein Fehler aufgetreten.",	type: 'error'});
        }
    }

    private async deleteAttribute(att : AttributeModel)
    {
        let ok = await ApiService.deleteAttribute(att);

        if (ok)
        {
			ElMessage({message: "Eigenschaft wurde gelöscht.", type: 'success'});
            await this.loadAttributeList();
        }
        else
        {
            // or show error message
			ElMessage({message: "Fragebogen konnte nicht gelöscht werden.",	type: 'error'});
        }
    }

    private onDeleteConfirm(action : string)
    {
        if (this.toDelete && action == 'confirm')
        {
            this.deleteAttribute(this.toDelete);
        }
    }

    private validateForm() : boolean
    {
        if (!this.newAttributeModel.name || this.newAttributeModel.name.length <= 0) return false;

        return true;
    }

    private onInput()
    {
        this.formValid = this.validateForm();
    }

    private async loadAttributeChoices()
    {
        // request choices for given attribute from server
        let list = await ApiService.getAllChoicesForAttribute(this.currentAttribute!.attributeid.toString());

        if (list)
        {
            this.choiceModelList = list;
        }
    }

    private async switchChoices(choiceSource : ChoiceModel, choiceTarget : ChoiceModel)
    {
        this.loadChoices = true;

        // get both positions
        let sourcePosition = choiceSource.position;
        let targetPosition = choiceTarget.position;

        // assign switched positions
        choiceSource.position = targetPosition;
        choiceTarget.position = sourcePosition;

        // send update request to server
        await ApiService.updateChoice(choiceSource);
        await ApiService.updateChoice(choiceTarget);

        // reload options list
        await this.loadAttributeChoices();

        this.dragId = 0;
        this.dragOverId = 0;
        this.dragPositionId = 0;

        this.loadChoices = false;
    }

    private async shiftChoice(choice : ChoiceModel)
    {
        this.loadChoices = true;

        await ApiService.reorderChoice(choice);

        await this.loadAttributeChoices();

        this.dragId = 0;
        this.dragOverId = 0;
        this.dragLastId = 0;
        this.dragPositionId = 0;

        this.loadChoices = false;

    }

    private async saveAttribute(attribute : AttributeModel)
    {
        let ok : any = await ApiService.updateAttribute(attribute);

        if (ok)
        {
			ElMessage({message: "Änderungen wurden übernommen.",	type: 'success'});
        }
        else
        {
            // or show error message
			ElMessage({message: "Änderungen konnten nicht gespeichert werden.",	type: 'error'});
        }
    }

    private async createChoice()
    {
        this.newChoiceModel.attributeid = this.currentAttribute!.attributeid;
        this.newChoiceModel.position = 1;

        if (this.choiceModelList.length > 0)
        {
            this.newChoiceModel.position = this.choiceModelList[this.choiceModelList.length-1].position+1;
        }

        let ok = await ApiService.createChoice(this.newChoiceModel);

        if (ok)
        {
            await this.loadAttributeChoices();
            this.newChoiceModel = {} as ChoiceModel;
            this.choiceFormValid = false;
        }
        else
        {
            // or show error message
			ElMessage({message: "Ausprägung konnte nicht hinzugefügt werden.",	type: 'error'});
        }
    }

    private async deleteChoice(choice : ChoiceModel)
    {
        let ok = await ApiService.deleteChoice(choice);

        if (ok)
        {
			ElMessage({message: "Ausprägung wurde gelöscht.",	type: 'success'});
            await this.loadAttributeChoices();
        }
        else
        {
            // or show error message
			ElMessage({message: "Ausprägung konnte nicht gelöscht werden.",	type: 'error'});
        }
    }

    private onAttributeDeleteClick(att : AttributeModel)
    {
        this.toDelete = att;

        ElMessageBox.confirm(`Soll die Eigenschaft "${att.name}" wirklich gelöscht werden?`, 'Warning', 
        {
            type: 'warning',
            title: '',
            callback: this.onDeleteConfirm,
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen'
        });
    }

    private onNewAttributeClick()
    {
        this.createAttribute();
    }

    private onAttributeSaveClick(attribute : AttributeModel)
    {
        this.saveAttribute(attribute);
    }

    private async onAttributeEditClick(attribute : AttributeModel)
    {
        this.currentAttribute = attribute;
        await this.loadAttributeChoices();
        this.showChoices = true;
    }
    
    private onDragOver(event : DragEvent)
    {
        event.preventDefault();
    }

    private onChoiceDragStart(choice : ChoiceModel)
    {
        this.dragId = choice.choiceid;
    }

    private onChoiceDragEnd()
    {
        this.dragId = 0;
        this.dragOverId = 0;
    }

    private onChoiceDragEnter(choice : ChoiceModel)
    {
        this.dragOverId = choice.choiceid;
        this.dragPositionId = 0;
    }

    private onChoiceDragLeave()
    {
        this.dragOverId = 0;
    }

    private onChoiceDrop(targetChoice : ChoiceModel)
    {
        if (this.dragId != this.dragOverId)
        {
            for (const sourceChoice of this.choiceModelList) 
            {
                if (sourceChoice.choiceid == this.dragId)
                {
                    this.switchChoices(sourceChoice, targetChoice);
                    break;
                }
            }
        }
    }

    private onPositionDragEnter(choice : ChoiceModel)
    {
        this.dragPositionId = choice.choiceid;
        this.dragOverId = 0;
        this.dragLastId = 0;
    }

    private onPositionDragLeave()
    {
        this.dragPositionId = 0;
    }

    private onPositionDrop(targetChoice : ChoiceModel)
    {
        if (this.dragId != this.dragPositionId)
        {
            for (const sourceChoice of this.choiceModelList) 
            {
                if (sourceChoice.choiceid == this.dragId)
                {
                    sourceChoice.position = targetChoice.position;
                    this.shiftChoice(sourceChoice);
                    break;
                }
            }
        }
        else
        {
            this.dragId = 0;
            this.dragOverId = 0;
            this.dragLastId = 0;
            this.dragPositionId = 0;
        }
    }

    private onLastDragEnter(choice : ChoiceModel)
    {
        this.dragLastId = choice.choiceid;
        this.dragOverId = 0;
        this.dragPositionId = 0;
    }

    private onLastDragLeave()
    {
        this.dragLastId = 0;
    }

    private onLastDrop()
    {
        if (this.dragId != this.dragPositionId)
        {
            for (const sourceChoice of this.choiceModelList) 
            {
                if (sourceChoice.choiceid == this.dragId)
                {
                    sourceChoice.position = this.choiceModelList[this.choiceModelList.length-1].position+1;
                    this.shiftChoice(sourceChoice);
                    break;
                }
            }
        }
    }

    private onDeleteDragEnter()
    {
        this.deleteMode = true;
    }

    private onDeleteDragLeave()
    {
        this.deleteMode = false;
    }

    private onNewChoiceClick()
    {
        // create new choice by sending request to server
        this.createChoice();
    }

    private onChoiceDelete()
    {
        for (const choice of this.choiceModelList) 
        {
            if (choice.choiceid == this.dragId)
            {
                this.deleteChoice(choice);
                break;
            }
        }

        this.deleteMode = false;
    }
}

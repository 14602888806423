import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Check, Delete, List, CirclePlusFilled, Briefcase } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop,
        List,
        Briefcase,
        CirclePlusFilled,
        Check,
        Delete
    },
})

export default class LocationEdit extends Vue 
{
    private activeName: string = "0";
    private customerId : string = '';
    private locationId : string = '';
    private customerName : string = '';
    private locationName : string = '';
    private customerModel : CustomerModel | undefined = {} as CustomerModel;
    private locationModel : LocationModel | undefined = {} as LocationModel;
    private departmentModelList : DepartmentModel[] = [] as DepartmentModel[];
    private departmentToDelete : DepartmentModel | undefined;
    private loading : boolean = true;
    private formLocationValid : boolean = true;
    private formDepartmentValid : boolean = false;
    private newDepartmentModel : DepartmentModel = {} as DepartmentModel;
    private rules =
    {
        name:
        [
            {
                required: true,
                message: "Bitte geben Sie eine Bezeichnung ein.",
                trigger: "blur"
            }
        ]
    };

    public async mounted()
    {
        // get location id from get request
        this.locationId = this.$route.params.locationid as string;

        // load location from db
        this.locationModel = await ApiService.getLocation(this.locationId);

        if (!this.locationModel) 
        {
            this.$router.push('/customer');
            return;
        }
        else
        {
            this.locationName =  this.locationModel.name;
            this.customerId = this.locationModel.customerid.toString();

            // load customer from db
            this.customerModel = await ApiService.getCustomer(this.customerId);

            if (!this.customerModel) 
            {
                this.$router.push('/customer');
                return;
            }
            else
            {
                this.customerName = this.customerModel.name;
            }

            // load departments of this location
            this.loadDepartmentList();
        }
    }

    private async loadDepartmentList()
    {
        this.loading = true;
        this.departmentModelList = await ApiService.getAllDepartmentsByLocationId(this.locationId) as DepartmentModel[];
        this.loading = false;
    }

    private async saveLocation(location : LocationModel)
    {
        let ok : any = await ApiService.updateLocation(location);

        if (ok)
        {
			ElMessage({message: "Änderungen wurden übernommen.",	type: 'success'});
        }
        else
        {
            // or show error message
			ElMessage({message: "Änderungen konnten nicht gespeichert werden.",	type: 'error'});
        }
    }

    private async createDepartment()
    {
        // send new department model via request to API and await
        this.newDepartmentModel.locationid = this.locationModel!.locationid;
        let department = await ApiService.createDepartment(this.newDepartmentModel);

        // check if successful
        if (department)
        {
			ElMessage({message: "Neue Abteilung wurde erfolgreich erstellt.",	type: 'success'});
            this.activeName = "2";
            this.newDepartmentModel = {} as DepartmentModel;
            this.formDepartmentValid = false;
            await this.loadDepartmentList();
        }
        else
        {
            // or show error message
			ElMessage({message: "Beim Erstellen der neuen Abteilung ist ein Fehler aufgetreten.",	type: 'error'});
        }
    }

    private async updateDepartment(department : DepartmentModel)
    {
        let ok : any = await ApiService.updateDepartment(department);

        if (ok)
        {
			ElMessage({message: "Änderungen wurden übernommen.",	type: 'success'});
        }
        else
        {
            // or show error message
			ElMessage({message: "Änderungen konnten nicht gespeichert werden.",	type: 'error'});
        }
    }

    private async deleteDepartment(department : DepartmentModel)
    {
        let ok = await ApiService.deleteDepartment(department);

        if (ok)
        {
			ElMessage({message: "Abteilung wurde gelöscht.",	type: 'success'});
            await this.loadDepartmentList();
        }
        else
        {
            // or show error message
			ElMessage({message: "Abteilung konnte nicht gelöscht werden.",	type: 'error'});
        }
    }

    private validateDepartmentForm() : boolean
    {
        if (!this.newDepartmentModel || !this.newDepartmentModel!.name || this.newDepartmentModel.name.length <= 0) return false;

        return true;
    }

    private validateLocationForm() : boolean
    {
        if (!this.locationModel || this.locationModel!.name.length <= 0) return false;

        return true;
    }

    private onDepartmentDeleteConfirm(action : string)
    {
        if (this.departmentToDelete && action == 'confirm')
        {
            this.deleteDepartment(this.departmentToDelete);
        }
    }

    private onLocationInput()
    {
        this.formLocationValid = this.validateLocationForm();
    }

    private onDepartmentInput()
    {
        this.formDepartmentValid = this.validateDepartmentForm();
    }

    private onLocationSaveClick(department : DepartmentModel)
    {
        if (this.locationModel)
        {
            this.saveLocation(this.locationModel);
        }
    }

    private onNewDepartmentClick(department : DepartmentModel)
    {
        this.createDepartment();
    }

    private onDepartmentSaveClick(department : DepartmentModel)
    {
        this.updateDepartment(department);
    }

    private onDepartmentDeleteClick(department : DepartmentModel)
    {
        this.departmentToDelete = department;

        ElMessageBox.confirm('Soll diese Abteilung wirklich gelöscht werden?', 'Warning', 
        {
            type: 'warning',
            title: '',
            callback: this.onDepartmentDeleteConfirm,
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen'
        });
    }
}

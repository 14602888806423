import axios from 'axios';

axios.defaults.withCredentials = true;

export default class ChartService
{
    static API_BASE_PATH: string = `${process.env.VUE_APP_REST_API_PATH}`;

	public static getTimestampForTimezone(sourceDate : Date, timeZone : string) : number
	{
        const timestamp = sourceDate.getTime();
		const date: Date = new Date(timestamp);
		const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US',
		{
    		timeZone,
		    year: 'numeric',
		    month: '2-digit',
		    day: '2-digit',
		    hour: '2-digit',
		    minute: '2-digit',
		    second: '2-digit',
		});

	  	return Math.floor(new Date(formatter.format(date)).getTime() / 1000);
	}
    
    public static getRandomColor() 
    {
        let chars : string = '0123456789ABCDEF';
        let color : string = '#';

        for (let i = 0; i < 6; i++) 
        {
            color += chars[Math.floor(Math.random() * 16)];
        }
        
        return color;
    }

    public static getBorderColor(color : string, percentage : number = 30, alpha : number = 0.5) : string
    {
        let r : number = parseInt(color.substr(1,2), 16);
        let g : number = parseInt(color.substr(3,2), 16);
        let b : number = parseInt(color.substr(5,2), 16);
        
        let br = Math.round(r * (1 - percentage / 100));
        let bg = Math.round(g * (1 - percentage / 100));
        let bb = Math.round(b * (1 - percentage / 100));

        return `rgb(${br},${bg},${bb},${alpha})`;
    }

	public static async getSamplePieChart()
    {
        try
        {
			let params = 
			{
				v: "v_sample_data"
			}

            let response = await axios.post(`${ChartService.API_BASE_PATH}/view`, params);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as any[];
                }
            }
        }
        catch (error)
        {
        }
    }
}

import axios from 'axios';

axios.defaults.withCredentials = true;

export default class SurveyService
{
    static API_SURVEY_PATH: string = `${process.env.VUE_APP_SURVEY_API_PATH}`;

	public static getTimestampForTimezone(sourceDate : Date, timeZone : string) : number
	{
        const timestamp = sourceDate.getTime();
		const date: Date = new Date(timestamp);
		const formatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US',
		{
    		timeZone,
		    year: 'numeric',
		    month: '2-digit',
		    day: '2-digit',
		    hour: '2-digit',
		    minute: '2-digit',
		    second: '2-digit',
		});

	  	return Math.floor(new Date(formatter.format(date)).getTime() / 1000);
	}

	public static async getToken(tokenid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/token/${tokenid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as TokenModel;
                }
            }
        }
        catch (error)
        {
        }
    }
	
	public static async getTokenByToken(token : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/token/${token}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as TokenModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getTokenList(tokenlistid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/tokenlist/${tokenlistid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as TokenListModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getCustomer(customerid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/customer/${customerid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CustomerModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllLocationsByCustomerId(customerid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/locations/${customerid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as LocationModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllDepartmentsByLocationId(locationid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/departments/${locationid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as DepartmentModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getQuestionnaire(questionnaireid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/questionnaire/${questionnaireid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionnaireModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getQuestionnaireByLoactionAndDepartment(locationid : string, departmentid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/questionnaire/${locationid}/${departmentid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionnaireModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getCategory(categoryid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/category/${categoryid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CategoryModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getParticipantForToken(tokenid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/participant/${tokenid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ParticipantModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async createParticipant(record : ParticipantModel)
    {
        try
        {
            let response = await axios.post(`${SurveyService.API_SURVEY_PATH}/participant`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as ParticipantModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async updateParticipant(record : ParticipantModel)
    {
        try
        {
            let response = await axios.put(`${SurveyService.API_SURVEY_PATH}/participant`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ParticipantModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllCategoriesForQuestionnaire(questionnaireid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/categories/${questionnaireid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CategoryModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllQuestionsForQuestionnaire(questionnaireid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/questions/${questionnaireid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as QuestionModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllOptionsForCategory(categoryid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/options/${categoryid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as OptionModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAnswerForParticipant(participantid : string, questionid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/answer/${participantid}/${questionid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as AnswerModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async createAnswer(record : AnswerModel)
    {
        try
        {
            let response = await axios.post(`${SurveyService.API_SURVEY_PATH}/answer`, record);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    return response.data as AnswerModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async setAnswerNote(record : AnswerModel)
    {
        try
        {
            let response = await axios.patch(`${SurveyService.API_SURVEY_PATH}/answer`, record);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as AnswerModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getNextCategory(participantid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/category/next/${participantid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CategoryModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllAttribute()
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/attributes`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as AttributeModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getCustomerAttribute(customerid : string, attributeid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/customer/${customerid}/attribute/${attributeid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as CustomerAttributeModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getAllChoicesForAttribute(attributeid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/attribute/${attributeid}/choices`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ChoiceModel[];
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async getChoiceForParticipant(participantid : string, attributeid : string)
    {
        try
        {
            let response = await axios.get(`${SurveyService.API_SURVEY_PATH}/participant/${participantid}/choice/${attributeid}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as ParticipantChoiceModel;
                }
            }
        }
        catch (error)
        {
        }
    }

	public static async setChoiceForParticipant(participantid : string, attributeid : string, choiceid : string)
    {
        try
        {
            let response = await axios.post(`${SurveyService.API_SURVEY_PATH}/participant/${participantid}/attribute/${attributeid}/choice/${choiceid}`);

            if (response && response.status === 201)
            {
                return true;
            }
        }
        catch (error)
        {
        }
    }

	public static async deleteChoiceForParticipant(participantid : string, attributeid : string, choiceid : string)
    {
        try
        {
            let response = await axios.delete(`${SurveyService.API_SURVEY_PATH}/participant/${participantid}/attribute/${attributeid}/choice/${choiceid}`);

            if (response && response.status === 204)
            {
                return true;
            }
        }
        catch (error)
        {
        }
    }

	public static async finishToken(tokenid : string)
    {
        try
        {
            let response = await axios.patch(`${SurveyService.API_SURVEY_PATH}/token/${tokenid}/finish`);

            if (response && response.status === 200)
            {
                return true;
            }
        }
        catch (error)
        {
        }
    }
}

import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { EditPen, Delete, Avatar, CirclePlusFilled } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop,
        EditPen,
        Delete,
        Avatar,
        CirclePlusFilled
    },
})

export default class Role extends Vue 
{
    private activeName: string = "1";
    private roleModelList : RoleModel[] = [];
    private loading : boolean = true;
    private formValid : boolean = false;
    private hasPermission: boolean | undefined = false;
    private toDelete : RoleModel | undefined;
    private newRoleModel : RoleModel = {} as RoleModel;
    private rules =
    {
        name:
        [
            {
                required: true,
                message: 'Bitte geben Sie einen Namen für die Rolle ein.',
                trigger: 'blur'
            }
        ],
    };

    public async mounted()
    {
        // get permission
        //this.hasPermission = await AuthService.hasPermission("2");
        this.hasPermission = true;

        this.loadRoles();
    }

    private async loadRoles()
    {
        this.loading = true;
        this.roleModelList = await ApiService.getAllRoles() as RoleModel[];
        this.loading = false;
    }

    private async createRole()
    {
        // send new role model via request to API and await
        let role : RoleModel | undefined = await ApiService.createRole(this.newRoleModel);

        // check if successful
        if (role)
        {
            ElMessage(
            {
               	message: "Neue Rolle erfolgreich erstellt.",
               	type: 'success',
            });

            this.activeName = "1";
            this.newRoleModel = {} as RoleModel;
            this.formValid = false;
            await this.loadRoles();
        }
        else
        {
            // or show error message
            ElMessage(
            {
               	message: "Beim Erstellen der neuen Rolle ist ein Fehler aufgetreten.",
               	type: 'error',
            });

        }
    }

    private async deleteRole(role : RoleModel)
    {
        let ok = await ApiService.deleteRole(role);

        if (ok)
        {
            ElMessage(
            {
               	message: "Rolle wurde gelöscht.",
               	type: 'success',
            });

            await this.loadRoles();
        }
        else
        {
            // or show error message
            ElMessage(
            {
               	message: "Rolle konnte nicht gelöscht werden.",
               	type: 'error',
            });

        }
    }

    private validateForm() : boolean
    {
        if (!this.newRoleModel.name || this.newRoleModel.name.length <= 0) return false;

        return true;
    }

    private onInput()
    {
        this.formValid = this.validateForm();
    }

    private onDeleteConfirm(action : string)
    {
        if (this.toDelete && action == 'confirm')
        {
            this.deleteRole(this.toDelete);
        }
    }

    private onRoleDeleteClick(role : RoleModel)
    {
        this.toDelete = role;

        ElMessageBox.confirm('Soll diese Rolle wirklich gelöscht werden?', 'Warning', 
        {
            type: 'warning',
            title: '',
            callback: this.onDeleteConfirm,
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen'
        });
    }

    private onRoleNewClick()
    {
        this.createRole();
    }

    private onRoleEditClick(role : RoleModel)
    {
        this.$router.push(`/role/${role.roleid}`);
    }
}

import { Vue, Options } from 'vue-class-component';
import { Check, Close, Search } from '@element-plus/icons-vue';
import ApiService from '@/services/ApiService';

@Options(
{
    props:
    {
        model:
        {
            type: Object,
            required: false
        }
    },
    components:
    {
        Search
    }
})


export default class QuestionnaireItem extends Vue 
{
    public model : QuestionnaireView;

    private loading : boolean = true;
    private finishedOnly : boolean = true;
    private risk : QuestionnaireRisk[] | undefined;

    private dashColors = 
    [
        { color: null, percentage: 10 },
        { color: null, percentage: 20 },
        { color: null, percentage: 30 },
        { color: null, percentage: 40 },
        { color: null, percentage: 50 },
        { color: null, percentage: 60 },
        { color: null, percentage: 70 },
        { color: null, percentage: 80 },
        { color: null, percentage: 90 },
        { color: null, percentage: 100 }
    ];

    public async mounted()
    {
        if (this.model)
        {
            this.risk = await ApiService.getQuestionnaireRisk(this.model);
            
            if (this.risk)
            {
                await this.loadDashColors();

                let totalRisk : number = 0;
                for (let r : getQuestionnaireRisk of this.risk)
                {
                    totalRisk += r.risk;
                }
                this.model.risk_total = Math.round(totalRisk / this.risk.length);
            }


            this.loading = false;
        }
    }

    private onReportClick()
    {
        this.$router.push(`/report/${this.model.questionnaireid.toString()}`);
    }

    private async loadDashColors()
    {
        let colors : string[] | undefined = await ApiService.getDashColors();
         
        for (let c : number = 0; c < colors.length; c++)
        {
            this.dashColors[c].color = colors[c].value;
        }
    }
}

import { Vue, Options } from 'vue-class-component';
import { useCookies } from "vue3-cookies";
import AuthService from '@/services/AuthService';
import { ElMessage } from 'element-plus';
import { User, Lock } from '@element-plus/icons-vue';

@Options(
{
    components:
    {
        User,
        Lock
    },
})

export default class Login extends Vue 
{
    private loading: boolean = false;
    private environmentCode = '';
    private userModel : UserModel | undefined = {} as UserModel;

    public async onLoginClick()
    {
        
        this.loading = true;
        

        if (this.userModel && this.userModel.username && this.userModel.password)
        {
            let user:UserModel | undefined = await AuthService.login(this.userModel!.username, this.userModel!.password);

            if (user && user.userid > 0)
            {
                this.$router.push('/');
            }
            else
            {
				ElMessage(
            	{
                	message: "Benutzername oder Passwort ungültig.",
                	type: 'error',
            	});
            }
        }

        this.loading = false;
    }

    public async mounted()
    {
        this.environmentCode = process.env.VUE_APP_ENV;
    }
}

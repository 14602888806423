import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Avatar, Suitcase, Finished } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop,
		Avatar,
		Finished
    },
})

export default class RoleEdit extends Vue
{
    private roleId: string = '';
    private roleName: string = '';
    private activeName: string = "0";
    private loading: boolean = true;
    private formRoleValid: boolean = false;
    private formPasswordValid: boolean = false;
    private hasPermission: boolean | undefined = false;
    private oldPassword: string = '';
    private newPassword: string = '';
    private repeatPassword: string = '';
    private roleModel: RoleModel | undefined = {} as RoleModel;
    private permissionModelList: PermissionModel[] | undefined = [] as PermissionModel[];
    private permissions : boolean[] = [] as boolean[];
    private rules =
    {
        name:
        [
            {
                required: true,
                message: "Bitte geben Sie einen Name für die Rolle ein.",
                trigger: "blur"
            }
        ],
        description:
        [
            {
                required: true,
                message: "Bitte geben Sie eine Beschreibung für die Rolle ein.",
                trigger: "blur"
            }
        ]
    };

    public async mounted()
    {
        // get permission
        //this.hasPermission = await AuthService.hasPermission("2");
        this.hasPermission = true;

        // get role id from get request
        this.roleId = this.$route.params.roleid as string;

        // load role from db
        this.roleModel = await ApiService.getRole(this.roleId);

        if (!this.roleModel)
        {
            this.$router.push('/role');
            return;
        }
        else
        {
            // get role name for breadcrumbs
            this.roleName = `${this.roleModel.name}`;

            // check form validity
            this.formRoleValid = this.validateRoleForm();

            // load role permissions
            this.loadRolePermissions();

            // load all permissions
            this.permissionModelList = await ApiService.getAllPermission();
        }

        this.loading = false;
    }

    private async loadRolePermissions()
    {
        if (this.roleModel)
        {
            // load role permissions
            this.roleModel.permissions = await ApiService.getPermissionsForRole(this.roleModel.roleid.toString());

            if (this.roleModel.permissions && this.roleModel.permissions.length > 0)
            {
                for (const p of this.roleModel.permissions)
                {
                    this.permissions[p.permissionid] = true;
                }
            }
        }
    }

    private async saveRole(role: RoleModel)
    {
        let ok: any = await ApiService.updateRole(role);

        if (ok)
        {
            this.roleName = `${role.name}`;
            ElMessage({message: "Änderungen wurden übernommen.", type: 'success'});
        }
        else
        {
            // or show error message
            ElMessage({message: "Änderungen konnten nicht gespeichert werden.", type: 'error'});
        }
    }

    private validateRoleForm() : boolean
    {
        if (!this.roleModel || !this.roleModel.name || this.roleModel.name.length <= 0) return false;

        return true;
    }

    private async changePermission(roleid : string, permissionid : number, permitted : boolean)
    {
        await ApiService.setRolePermission(Number(roleid), permissionid, permitted);
        await this.loadRolePermissions();
    }

    private onInput()
    {
        this.formRoleValid = this.validateRoleForm();
    }

    private onPermissionChange(permission: PermissionModel)
    {
        this.changePermission(this.roleId, permission.permissionid, this.permissions[permission.permissionid]);
    }

    private onRoleSaveClick()
    {
        if (this.roleModel)
        {
            this.saveRole(this.roleModel);
        }
    }
}

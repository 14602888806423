import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Checked, Delete, EditPen, CirclePlusFilled, CopyDocument } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop,
		Checked,
		Delete,
		EditPen,
		CirclePlusFilled,
		CopyDocument
    }
})

export default class Questionnaire extends Vue 
{
    private activeName: string = "1";
    private questModelList : QuestionnaireView[] = [] as QuestionnaireView[];
    private loading : boolean = true;
    private formValid : boolean = false;
    private formCopyValid : boolean = false;
    private hasMainPermission: boolean | undefined = false;
    private hasSubPermission: boolean | undefined = false;
    private toDelete : QuestionnaireModel | undefined;
    private toCopy : QuestionnaireModel | undefined = {} as QuestionnaireModel;
    private newQuestModel : QuestionnaireModel = {} as QuestionnaireModel;
    private copyQuestModel : QuestionnaireModel = {} as QuestionnaireModel;
    private customerModelList : CustomerModel[] | undefined = [] as CustomerModel[];
    private locationModelList : LocationModel[] | undefined = [] as LocationModel[];
    private departmentModelList : DepartmentModel[] | undefined = [] as DepartmentModel[];
    private copyCustomerModelList : CustomerModel[] | undefined = [] as CustomerModel[];
    private copyLocationModelList : LocationModel[] | undefined = [] as LocationModel[];
    private copyDepartmentModelList : DepartmentModel[] | undefined = [] as DepartmentModel[];
    private rules =
    {
        name:
        [
            {
                required: true,
                message: "Bitte geben Sie eine Bezeichnung ein.",
                trigger: "blur"
            }
        ],
        customerid:
        [
            {
                required: true,
                message: "Bitte wählen Sie einen Mandanten.",
                trigger: "blur"
            }
        ],
        locationid:
        [
            {
                required: true,
                message: "Bitte wählen Sie einen Standort.",
                trigger: "blur"
            }
        ],
        departmentid:
        [
            {
                required: true,
                message: "Bitte wählen Sie eine Abteilung.",
                trigger: "blur"
            }
        ],
        valid_from:
        [
            {
                required: true,
                message: "Bitte geben Sie das Anfangsdatum der Gültigkeit ein.",
                trigger: "blur"
            }
        ],
        valid_until:
        [
            {
                required: true,
                message: "Bitte geben Sie das Endedatum der Gültigkeit ein.",
                trigger: "blur"
            }
        ]
    };

    private copyrules =
    {
        name:
        [
            {
                required: true,
                message: "Bitte geben Sie eine Bezeichnung ein.",
                trigger: "blur"
            }
        ],
        questionnaireid:
        [
            {
                required: true,
                message: "Bitte wählen Sie einen Fragebogen als Vorlage aus.",
                trigger: "blur"
            }
        ],
        customerid:
        [
            {
                required: true,
                message: "Bitte wählen Sie einen Mandanten.",
                trigger: "blur"
            }
        ],
        locationid:
        [
            {
                required: true,
                message: "Bitte wählen Sie einen Standort.",
                trigger: "blur"
            }
        ],
        departmentid:
        [
            {
                required: true,
                message: "Bitte wählen Sie eine Abteilung.",
                trigger: "blur"
            }
        ],
        valid_from:
        [
            {
                required: true,
                message: "Bitte geben Sie das Anfangsdatum der Gültigkeit ein.",
                trigger: "blur"
            }
        ],
        valid_until:
        [
            {
                required: true,
                message: "Bitte geben Sie das Endedatum der Gültigkeit ein.",
                trigger: "blur"
            }
        ]
    };

    public async mounted()
    {
        // get permissions
        //this.hasMainPermission = await AuthService.hasPermission("7");
        //this.hasSubPermission = await AuthService.hasPermission("8");
        this.hasMainPermission = true;
        this.hasSubPermission = true;

        this.customerModelList = await ApiService.getAllCustomers();
        this.copyCustomerModelList = await ApiService.getAllCustomers();

        await this.loadQuestionnaireList();
    }
		
    private async loadQuestionnaireList()
    {
        this.loading = true;
        this.questModelList = await ApiService.getAllQuestionnairesView() as QuestionnaireView[];
        this.loading = false;
    }

    private async createQuestionnaire()
    {
		// convert date selection to UTC timestamp
        this.newQuestModel.valid_from = ApiService.getTimestampForTimezone(this.newQuestModel.valid_from, "Europe/Berlin") as any;
        this.newQuestModel.valid_until = ApiService.getTimestampForTimezone(this.newQuestModel.valid_until, "Europe/Berlin") as any;
        
		// send new model via request to API and await
        let quest = await ApiService.createQuestionnaire(this.newQuestModel);

        // check if successful
        if (quest)
        {
			ElMessage({message: "Neuer Fragebogen wurde erfolgreich erstellt.",	type: 'success'});
            this.activeName = "1";
            this.newQuestModel = {} as QuestionnaireModel;
            this.formValid = false;
            await this.loadQuestionnaireList();
        }
        else
        {
            // or show error message
			ElMessage({message: "Beim Erstellen des neuen Fragebogens ist ein Fehler aufgetreten.",	type: 'error'});
        }
    }

    private async copyQuestionnaire()
    {
        // check if a questionnaire has been selected
        if (!this.toCopy || this.toCopy.questionnaireid <= 0)
        {
			ElMessage({message: "Es wurde kein Fragebogen als Vorlage ausgewählt.",	type: 'error'});
            return;
        }

        // send new model via request to API and await
        let quest = await ApiService.copyQuestionnaire(this.copyQuestModel, this.toCopy);

        // check if successful
        if (quest)
        {
            // copy questions from selected questionnaire
			ElMessage({message: "Neuer Fragebogen wurde erfolgreich erstellt.",	type: 'success'});
            this.activeName = "1";
            this.newQuestModel = {} as QuestionnaireModel;
            this.formValid = false;
            await this.loadQuestionnaireList();
        }
        else
        {
            // or show error message
			ElMessage({message: "Beim Erstellen des neuen Fragebogens ist ein Fehler aufgetreten.",	type: 'error'});
        }
    }

    private async deleteQuestionnaire(quest : QuestionnaireModel)
    {
        let ok = await ApiService.deleteQuestionnaire(quest);

        if (ok)
        {
			ElMessage({message: "Fragebogen wurde gelöscht.",	type: 'success'});
            await this.loadQuestionnaireList();
        }
        else
        {
            // or show error message
			ElMessage({message: "Fragebogen konnte nicht gelöscht werden.",	type: 'error'});
        }
    }

    private onDeleteConfirm(action : string)
    {
        if (this.toDelete && action == 'confirm')
        {
            this.deleteQuestionnaire(this.toDelete);
        }
    }

    private validateForm() : boolean
    {
        if (!this.newQuestModel.name || this.newQuestModel.name.length <= 0) return false;

        if (!this.newQuestModel.customerid || this.newQuestModel.customerid <= 0) return false;

        if (!this.newQuestModel.departmentid || this.newQuestModel.departmentid <= 0) return false;

        if (!this.newQuestModel.valid_from) return false;

        if (!this.newQuestModel.valid_until) return false;

        if (this.newQuestModel.valid_until <= new Date())
        {
			ElMessage({message: "Des Endedatum des Gültigkeitszeitraums liegt in der Vergangenheit.",	type: 'error'});
            return false;
        }

        if (this.newQuestModel.valid_until < this.newQuestModel.valid_from)
        {
			ElMessage({message: "Des Endedatum des Gültigkeitszeitraums liegt vor dem Anfangsdatum.",	type: 'error'});
            return false;
        }
        
        // check, if valid dates are colliding with another questionnaire for this customer
        for (let quest : QuestionnaireModel of this.questModelList)
        {
            if (quest.customerid == this.newQuestModel.customerid && quest.locationid == this.newQuestModel.locationid && quest.departmentid == this.newQuestModel.departmentid)
            {
                let checkFrom : Date = new Date(0).setUTCSeconds(quest.valid_from);
                let checkUntil : Date = new Date(0).setUTCSeconds(quest.valid_until);

                if (!(this.newQuestModel.valid_from > checkUntil || this.newQuestModel.valid_until < checkFrom))
                {
			        ElMessage({message: "Der Gültigkeitszeitraum kollidiert mit einem anderen Fragebogen.",	type: 'error'});
                    return false;
                }
            }
        }

        return true;
    }

    private validateCopyForm() : boolean
    {
        if (!this.toCopy || !this.toCopy.questionnaireid) return false;

        if (!this.copyQuestModel.name || this.copyQuestModel.name.length <= 0) return false;

        if (!this.copyQuestModel.customerid || this.copyQuestModel.customerid <= 0) return false;

        if (!this.copyQuestModel.departmentid || this.copyQuestModel.departmentid <= 0) return false;

        if (!this.copyQuestModel.valid_from) return false;

        if (!this.copyQuestModel.valid_until) return false;

        if (this.copyQuestModel.valid_until <= new Date())
        {
			ElMessage({message: "Des Endedatum des Gültigkeitszeitraums liegt in der Vergangenheit.",	type: 'error'});
            return false;
        }

        if (this.copyQuestModel.valid_until < this.copyQuestModel.valid_from)
        {
			ElMessage({message: "Des Endedatum des Gültigkeitszeitraums liegt vor dem Anfangsdatum.",	type: 'error'});
            return false;
        }

        // check, if valid dates are colliding with another questionnaire for this customer
        if (this.questModelList && this.questModelList.length > 0)
        {
            for (let quest : QuestionnaireModel of this.questModelList)
            {
                if (quest.customerid == this.copyQuestModel.customerid && quest.locationid == this.copyQuestModel.locationid && quest.departmentid == this.copyQuestModel.departmentid)
                {
                    let checkFrom : Date = new Date(0).setUTCSeconds(quest.valid_from);
                    let checkUntil : Date = new Date(0).setUTCSeconds(quest.valid_until);

                    if (!(this.copyQuestModel.valid_from > checkUntil || this.copyQuestModel.valid_until < checkFrom))
                    {
                        ElMessage({message: "Der Gültigkeitszeitraum kollidiert mit einem anderen Fragebogen.",	type: 'error'});
                        return false;
                    }
                }
            }
        }

        return true;
    }

    private onInput()
    {
        this.formValid = this.validateForm();
    }

    private onCopyInput()
    {
        this.formCopyValid = this.validateCopyForm();
    }

    private async onQuestionnaireChange(questionnaireid : string)
    {
        this.formCopyValid = this.validateCopyForm();
    }

    private async onCustomerChange(customerid : string)
    {
        // get locations for selected customer
        this.locationModelList = await ApiService.getAllLocationsByCustomerId(customerid);

        this.newQuestModel.locationid = null;

        this.formValid = this.validateForm();
    }

    private async onCopyCustomerChange(customerid : string)
    {
        // get locations for selected customer
        this.copyLocationModelList = await ApiService.getAllLocationsByCustomerId(customerid);

        // remove possible prior selection
        this.copyQuestModel.locationid = null;

        this.formCopyValid = this.validateCopyForm();
    }

    private async onLocationChange(locationid : string)
    {
        // get departments for selected location
        this.departmentModelList = await ApiService.getAllDepartmentsByLocationId(locationid);

        // remove possible prior selection
        this.newQuestModel.departmentid = null;

        this.formValid = this.validateForm();
    }

    private async onCopyLocationChange(locationid : string)
    {
        // get departments for selected location
        this.copyDepartmentModelList = await ApiService.getAllDepartmentsByLocationId(locationid);

        // remove possible prior selection
        this.copyQuestModel.departmentid = null;

        this.formCopyValid = this.validateCopyForm();
    }

    private onDepartmentChange(departmentid : string)
    {
        this.formValid = this.validateForm();
    }

    private onCopyDepartmentChange(departmentid : string)
    {
        this.formCopyValid = this.validateCopyForm();
    }

    private onQuestionnaireDeleteClick(quest : QuestionnaireModel)
    {
        this.toDelete = quest;

        ElMessageBox.confirm(`Soll der Fragebogen "${quest.name}" wirklich gelöscht werden?`, 'Warning', 
        {
            type: 'warning',
            title: '',
            callback: this.onDeleteConfirm,
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen'
        });
    }

    private onQuestionnaireEditClick(quest : QuestionnaireModel)
    {
        this.$router.push(`/questionnaire/${quest.questionnaireid}`);
    }

    private onNewQuestionnaireClick()
    {
        this.createQuestionnaire();
    }

    private onCopyQuestionnaireClick()
    {
        this.copyQuestionnaire();
    }
}

import { Vue, Options } from 'vue-class-component';
import { ColdDrink, Lock } from '@element-plus/icons-vue';

@Options(
    {
        components:
        {
            ColdDrink,
            Lock
        },
    })

export default class StepExpired extends Vue 
{
    public mounted()
    {
        
    }

    
    private onBackClick()
    {
        this.$router.push('/start');
    }
}

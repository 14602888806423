import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox, MessageOptions } from 'element-plus';
import SurveyService from '@/services/SurveyService';
import AuthService from '@/services/AuthService';

@Options(
{
    components:
    {
    },
})

export default class Start extends Vue 
{

    private token : string = '';

    public mounted()
    {
    
    }

    private async loadTokenAndStart()
    {
        // request participation
        let ok = await AuthService.participate(this.token);
        
        if (ok)
        {
            // lookup token model in database
            let tokenModel : any = await SurveyService.getTokenByToken(this.token);

            // if token exists, load start page
            if (tokenModel)
            {
                // remove user (if logged in before)
                localStorage.removeItem('user');

                // route to start
                this.$router.push(`/start/${tokenModel.tokenid}`);
                return;
            }
        }

        // in case login was not successful, show error message
        let options : MessageOptions = {} as MessageOptions;
        options.type = 'error';
        options.offset = 180;
        options.message = 'Der von Ihnen eingegebene Ticket-Code ist ungültig oder abgelaufen.';
		ElMessage(options);
    }

    private onTokenEnter()
    {
        this.token = this.token.toUpperCase();
    }

    private onSubmit()
    {
        if (this.token.length > 4)
        {
            this.loadTokenAndStart();
        }
        else
        {
            let options : MessageOptions = {} as MessageOptions;
            options.type = 'error';
            options.offset = 180;
            options.message = 'Der von Ihnen eingegebene Ticket-Code ist ungültig. Bitte versuchen Sie es erneut.';
            ElMessage(options);
        }
    }
}

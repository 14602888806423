import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, resolveDirective as _resolveDirective, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7284a8b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "sim" }
const _hoisted_2 = { class: "progress" }
const _hoisted_3 = { class: "log" }

export function render(_ctx, _cache) {
  const _component_Ship = _resolveComponent("Ship")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_progress = _resolveComponent("el-progress")
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")
  const _component_el_timeline = _resolveComponent("el-timeline")
  const _directive_loading = _resolveDirective("loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, [
      _createVNode(_component_el_icon, { size: 48 }, {
        default: _withCtx(() => [
          _createVNode(_component_Ship)
        ]),
        _: 1
      }),
      _createTextVNode(" Simulation")
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _createVNode(_component_el_form, {
        ref: "json",
        rules: _ctx.rules,
        "label-position": "top",
        model: _ctx.simModel,
        "label-width": "120px"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: "Input",
            prop: "input"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                type: "textarea",
                rows: "15",
                modelValue: _ctx.simModel.input,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.simModel.input) = $event)),
                onInput: _ctx.onInput
              }, null, 8, ["modelValue", "onInput"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_form_item, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                type: "primary",
                disabled: !_ctx.formValid,
                onClick: _ctx.onStartClick
              }, {
                default: _withCtx(() => [
                  _createTextVNode("Start")
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["rules", "model"])
    ], 512), [
      [_vShow, !_ctx.running]
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_progress, {
          "text-inside": true,
          "stroke-width": 48,
          percentage: _ctx.percentage
        }, null, 8, ["percentage"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_el_timeline, { class: "log" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.timeline, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_timeline_item, {
                type: item.finished ? 'success' : 'primary',
                key: index,
                timestamp: item.timestamp
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.content), 1)
                ]),
                _: 2
              }, 1032, ["type", "timestamp"]))
            }), 128))
          ]),
          _: 1
        })
      ])
    ])), [
      [_vShow, _ctx.running],
      [_directive_loading, _ctx.loading]
    ])
  ]))
}
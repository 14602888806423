import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-86acfc74"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "chart"
}

export function render(_ctx, _cache) {
  const _component_Pie = _resolveComponent("Pie")

  return (_ctx.showChart)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Pie, {
          data: _ctx.chartModel,
          options: _ctx.options
        }, null, 8, ["data", "options"])
      ]))
    : _createCommentVNode("", true)
}
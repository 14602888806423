import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-27170dca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-box" }
const _hoisted_2 = { class: "env" }

export function render(_ctx, _cache) {
  const _component_el_header = _resolveComponent("el-header")
  const _component_User = _resolveComponent("User")
  const _component_el_icon = _resolveComponent("el-icon")
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_Lock = _resolveComponent("Lock")
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_main = _resolveComponent("el-main")
  const _component_el_container = _resolveComponent("el-container")

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createVNode(_component_el_container, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_header, { class: "blue" }, {
              default: _withCtx(() => [
                _createTextVNode("Willkommen"),
                _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.environmentCode), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_main, { class: "login-box" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_form, {
                  modelValue: _ctx.userModel,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.userModel) = $event)),
                  ref: "form",
                  onSubmit: _withModifiers(_ctx.onLoginClick, ["prevent"])
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      class: "field",
                      prop: "username"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.userModel.username,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.userModel.username) = $event)),
                          class: "login",
                          placeholder: "Benutzer",
                          disabled: _ctx.loading
                        }, {
                          prefix: _withCtx(() => [
                            _createVNode(_component_el_icon, { class: "el-input__icon" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_User)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_form_item, {
                      class: "field",
                      prop: "password"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          modelValue: _ctx.userModel.password,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.userModel.password) = $event)),
                          placeholder: "Passwort",
                          type: "password",
                          disabled: _ctx.loading
                        }, {
                          prefix: _withCtx(() => [
                            _createVNode(_component_el_icon, { class: "el-input__icon" }, {
                              default: _withCtx(() => [
                                _createVNode(_component_Lock)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue", "disabled"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_button, {
                      loading: _ctx.loading,
                      type: "primary",
                      "native-type": "submit",
                      class: "login"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Anmelden ")
                      ]),
                      _: 1
                    }, 8, ["loading"])
                  ]),
                  _: 1
                }, 8, ["modelValue", "onSubmit"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
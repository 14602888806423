import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Brush, Finished } from '@element-plus/icons-vue';
import Settings from '@/enums/Settings';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop,
	    Brush,
		Finished
    },
})

export default class Setting extends Vue
{
    private activeName: string = "1";
    private loading: boolean = true;
    private hasPermission: boolean | undefined = false;
    private dashPercentage : number = 0;
    private dashColors = 
    [
        { color: null, percentage: 10 },
        { color: null, percentage: 20 },
        { color: null, percentage: 30 },
        { color: null, percentage: 40 },
        { color: null, percentage: 50 },
        { color: null, percentage: 60 },
        { color: null, percentage: 70 },
        { color: null, percentage: 80 },
        { color: null, percentage: 90 },
        { color: null, percentage: 100 }
    ];

    public async mounted()
    {
        // get permission
        //this.hasPermission = await AuthService.hasPermission("2");
        this.hasPermission = true;

        await this.loadSettings();

        this.loading = false;
    }

    private async loadSettings()
    {
        let colors : string[] | undefined = await ApiService.getDashColors();
         
        for (let c : number = 0; c < colors.length; c++)
        {
            this.dashColors[c].color = colors[c].value;
        }
    }

    private async updateColor(index : number)
    {
        let setting : SettingModel = {} as SettingModel;

        switch (index)
        {
            case 0:
                setting.settingid = Settings.DASH_COLOR_1;
                break;
            case 1:
                setting.settingid = Settings.DASH_COLOR_2;
                break;
            case 2:
                setting.settingid = Settings.DASH_COLOR_3;
                break;
            case 3:
                setting.settingid = Settings.DASH_COLOR_4;
                break;
            case 4:
                setting.settingid = Settings.DASH_COLOR_5;
                break;
            case 5:
                setting.settingid = Settings.DASH_COLOR_6;
                break;
            case 6:
                setting.settingid = Settings.DASH_COLOR_7;
                break;
            case 7:
                setting.settingid = Settings.DASH_COLOR_8;
                break;
            case 8:
                setting.settingid = Settings.DASH_COLOR_9;
                break;
            case 9:
                setting.settingid = Settings.DASH_COLOR_10;
                break;
        }

        setting.value = this.dashColors[index].color;
        await ApiService.updateSetting(setting);
    }

    public onColorUpdate(index : number)
    {
        console.log(index);
        this.updateColor(index);
    }
}

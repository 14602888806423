import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { EditPen, Delete, UserFilled, CirclePlusFilled } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop,
        EditPen,
        Delete,
        UserFilled,
        CirclePlusFilled
    },
})

export default class User extends Vue 
{
    private activeName: string = "1";
    private userModelList : UserModel[] = [];
    private loading : boolean = true;
    private formValid : boolean = false;
    private hasPermission: boolean | undefined = false;
    private toDelete : UserModel | undefined;
    private newUserModel : UserModel = {} as UserModel;
    private rules =
    {
        email:
        [
            {
                type: 'email',
                required: true,
                message: 'Bitte geben Sie eine gültige E-Mail des neuen Benutzers ein.',
                trigger: 'blur'
            }
        ],
        username:
        [
            {
                required: true,
                message: 'Bitte geben Sie einen Benutzernamen ein.',
                trigger: 'blur'
            }
        ],
        firstname:
        [
            {
                required: true,
                message: 'Bitte geben Sie den Vornamen des Benutzers ein.',
                trigger: 'blur'
            }
        ],
        lastname:
        [
            {
                required: true,
                message: 'Bitte geben Sie den Nachnamen des Benutzers ein.',
                trigger: 'blur'
            }
        ]
    };

    public async mounted()
    {
        // get permission
        //this.hasPermission = await AuthService.hasPermission("2");
        this.hasPermission = true;

        this.loadUsers();
    }

    private async loadUsers()
    {
        this.loading = true;
        this.userModelList = await ApiService.getAllUsers() as UserModel[];
        this.loading = false;
    }

    private async createUser()
    {
        // send new user model via request to API and await
        this.newUserModel.roleid = 4;
        this.newUserModel.statusid = 1;
        let user : UserModel | undefined = await ApiService.createUser(this.newUserModel);

        // check if successful
        if (user)
        {
            ElMessage(
            {
               	message: "Neuer Benutzer erfolgreich erstellt.",
               	type: 'success',
            });

            this.activeName = "1";
            this.newUserModel = {} as UserModel;
            this.formValid = false;
            await this.loadUsers();
        }
        else
        {
            // or show error message
            ElMessage({message: "Beim Erstellen des neuen Benutzers ist ein Fehler aufgetreten.", type: 'error',});
        }
    }

    private async deleteUser(user : UserModel)
    {
        let ok = await ApiService.deleteUser(user);

        if (ok)
        {
            ElMessage(
            {
               	message: "Benutzer wurde gelöscht.",
               	type: 'success',
            });

            await this.loadUsers();
        }
        else
        {
            // or show error message
            ElMessage(
            {
               	message: "Benutzer konnte nicht gelöscht werden.",
               	type: 'error',
            });

        }
    }

    private validateEmail()
    {
        if (!this.newUserModel.email || this.newUserModel.email.length <= 0) return false;

        let re : RegExp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        return re.test(this.newUserModel.email);
    }

    private validateForm() : boolean
    {
        if (!this.validateEmail()) return false;

        if (!this.newUserModel.username || this.newUserModel.username.length <= 0) return false;

        if (!this.newUserModel.firstname || this.newUserModel.firstname.length <= 0) return false;

        if (!this.newUserModel.lastname || this.newUserModel.lastname.length <= 0) return false;

        return true;
    }

    private onInput()
    {
        this.formValid = this.validateForm();
    }

    private onDeleteConfirm(action : string)
    {
        if (this.toDelete && action == 'confirm')
        {
            this.deleteUser(this.toDelete);
        }
    }

    private onUserDeleteClick(user : UserModel)
    {
        this.toDelete = user;

        ElMessageBox.confirm('Soll dieser Benutzer wirklich gelöscht werden?', 'Warning', 
        {
            type: 'warning',
            title: '',
            callback: this.onDeleteConfirm,
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen'
        });
    }

    private onUserNewClick()
    {
        this.createUser();
    }

    private onUserEditClick(user : UserModel)
    {
        this.$router.push(`/user/${user.userid}`);
    }
}

import axios from 'axios';

axios.defaults.withCredentials = true;

export default class SimulationService
{
    static API_SIMULATION_PATH: string = `${process.env.VUE_APP_SIMULATION_API_PATH}`;

    public static async build(data : string)
    {
        let result : SimulationModel = {} as SimulationModel;

        try
        {
            let json = JSON.parse(data);
            let response = await axios.post(`${SimulationService.API_SIMULATION_PATH}/build`, json);

            if (response && response.status === 201)
            {
                if (response.data)
                {
                    result = response.data as SimulationModel;
                }
            }
        }
        catch (error)
        {
            console.log(error);
        }

        return result;
    }

    public static async step(simulationid : number)
    {
        try
        {
            let response = await axios.get(`${SimulationService.API_SIMULATION_PATH}/${simulationid}/step`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as number;
                }
            }
        }
        catch (error)
        {
        }
    }
}

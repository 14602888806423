import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { EditPen, Delete, List, PictureFilled, LocationFilled, CirclePlusFilled, Ticket, Checked, View } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';

interface TransferItem
{
    key : number;
    label : string;
    disabled : boolean;
}

@Options(
{
    components:
    {
        NavBarTop,
		EditPen,
		Delete,
		List,
		PictureFilled,
		LocationFilled,
		CirclePlusFilled,
		Ticket,
		Checked,
		View
    },
})

export default class CustomerEdit extends Vue 
{
    private activeName: string = "0";
    private customerId : string = '';
    private customerName : string = '';
    private numberOfTickets : number = 1;
    private customerModel : CustomerModel | undefined = {} as CustomerModel;
    private attributeModelList : AttributeModel[] = [] as AttributeModel[];
    private locationModelList : LocationModel[] = [] as LocationModel[];
    private tokenlistModelList : TokenListView[] = [] as TokenListView[];
    private tokenModelList : TokenView[] | undefined = [] as TokenView[];
    private locationToDelete : LocationModel | undefined;
    private ticketListToDelete : TokenListModel | undefined;
    private loading : boolean = true;
    private loadingTokens : boolean = true;
    private formCustomerValid : boolean = true;
    private formLocationValid : boolean = false;
    private formTicketlistValid : boolean = false;
    private hasPermission: boolean | undefined = false;
    private showTickets : boolean = false;
    private newLocationModel : LocationModel = {} as LocationModel;
    private newTicketlistModel : TokenListModel = {} as TokenListModel;
    private currentTicketlistModel : TokenListModel = {} as TokenListModel;
    private questionnaireModelList : QuestionnaireModel[] = [] as QuestionnaireModel[];
    private bannerURL : string | undefined = '';
    private selectedAttributes : number[] = [] as number[];
    private availableAttributes : TransferItem[] = [] as TransferItem[];
    private tokenPage : number = 0;
    private tokenCount : number = 100;
    private tokenTableCount : number = 0;
    private questName : string = "";


    private rules =
    {
        name:
        [
            {
                required: true,
                message: "Bitte geben Sie eine Bezeichnung ein.",
                trigger: "blur"
            }
        ],
        valid_from:
        [
            {
                required: true,
                message: "Bitte geben Sie das Anfangsdatum der Gültigkeit ein.",
                trigger: "blur"
            }
        ],
        valid_until:
        [
            {
                required: true,
                message: "Bitte geben Sie das Endedatum der Gültigkeit ein.",
                trigger: "blur"
            }
        ]
    };

    public async mounted()
    {
        // get permission
        //this.hasPermission = await AuthService.hasPermission("6");
        this.hasPermission = true;
        
        // get customer id from get request
        this.customerId = this.$route.params.customerid as string;

        // load customer from db
        this.customerModel = await ApiService.getCustomer(this.customerId);

        if (!this.customerModel) 
        {
            this.$router.push('/customer');
            return;
        }
        else
        {
            this.customerName =  this.customerModel.name;
            if (this.customerModel.banner_size > 0)
            {
                //await this.loadBanner();
            }
        }

        // load locations of this customer
        this.loadLocationList();

        // load ticketlists for this customer
        this.loadTicketList();

        // load available attributes
        this.loadAttributeList();

        // load available questionnaires
        this.loadQuestionnaireList();
    }

    private async loadAttributeList()
    {
        if (this.customerModel)
        {
            this.attributeModelList = await ApiService.getAllAttributes() as AttributeModel[];
            
            if (!this.attributeModelList) return;

            for (let attribute of this.attributeModelList)
            {
                let item : TransferItem = {} as TransferItem;
                item.key = attribute.attributeid;
                item.label = attribute.name;
                item.disabled = attribute.isdefault;

                if (attribute.isrequired)
                {
                    item.label = item.label+'*';
                }

                this.availableAttributes.push(item);

                let att : CustomerAttributeModel | undefined = await ApiService.getCustomerAttribute(this.customerModel.customerid.toString(), attribute.attributeid.toString());

                if (attribute.isdefault || att)
                {
                    this.selectedAttributes.push(item.key);
                }
            }
        }
    }

    private async loadQuestionnaireList()
    {
        this.loading = true;
        this.questionnaireModelList = await ApiService.getAllQuestionnaireForCustomer(this.customerId) as QuestionnaireModel[];
        this.loading = false;
    }

    private async loadLocationList()
    {
        this.loading = true;
        this.locationModelList = await ApiService.getAllLocationsByCustomerId(this.customerId) as LocationModel[];
        this.loading = false;
    }

    private async loadTicketList()
    {
        this.loading = true;
        this.tokenlistModelList = await ApiService.getTokenListsForCustomer(this.customerId) as TokenListView[];
        this.loading = false;
    }

    private async saveCustomer(customer : CustomerModel)
    {
        let ok : any = await ApiService.updateCustomer(customer);

        if (ok)
        {
			ElMessage({message: "Änderungen wurden übernommen.", type: 'success'});
        }
        else
        {
            // or show error message
			ElMessage({message: "Änderungen konnten nicht gespeichert werden.",	type: 'error'});
        }
    }

    private async createLocation()
    {
        // send new department model via request to API and await
        this.newLocationModel.customerid = this.customerModel!.customerid;
        let location = await ApiService.createLocation(this.newLocationModel);

        // check if successful
        if (location)
        {
			ElMessage({message: "Neuer Standort wurde erfolgreich erstellt.", type: 'success'});
            this.activeName = "3";
            this.newLocationModel = {} as LocationModel;
            this.formLocationValid = false;
            await this.loadLocationList();
        }
        else
        {
            // or show error message
			ElMessage({message: "Beim Erstellen des neuen Standorts ist ein Fehler aufgetreten.",	type: 'error'});
        }
    }

    private async updateLocation(location : LocationModel)
    {
        let ok : any = await ApiService.updateLocation(location);

        if (ok)
        {
			ElMessage({message: "Änderungen wurden übernommen.", type: 'success'});
        }
        else
        {
            // or show error message
			ElMessage({message: "Änderungen konnten nicht gespeichert werden.",	type: 'error'});
        }
    }

    private async deleteLocation(location : LocationModel)
    {
        let ok = await ApiService.deleteLocation(location);

        if (ok)
        {
			ElMessage({message: "Standort wurde gelöscht.",	type: 'success'});
            await this.loadLocationList();
        }
        else
        {
            // or show error message
			ElMessage({message: "Standort konnte nicht gelöscht werden.",	type: 'error'});
        }
    }

    private async createTicketlist()
    {
		// set loading indicator
		this.loading = true;
        
        // convert selected dates to timestamps
        this.newTicketlistModel.valid_from = ApiService.getTimestampForTimezone(this.newTicketlistModel.valid_from, "Europe/Berlin") as any;
        this.newTicketlistModel.valid_until = ApiService.getTimestampForTimezone(this.newTicketlistModel.valid_until, "Europe/Berlin") as any;

        // send new ticketlist model via request to API and await
        this.newTicketlistModel.customerid = this.customerModel!.customerid;
        console.log(this.newTicketlistModel);
        let ticketlist = await ApiService.createTokenList(this.newTicketlistModel);

        // check if successful
		if (ticketlist)
		{
			await ApiService.generateTokens(ticketlist.tokenlistid, this.numberOfTickets);

			ElMessage({message: "Neue Ticketliste wurde erfolgreich erstellt.",	type: 'success'});
			this.activeName = "5";
			this.numberOfTickets = 0;
			this.newTicketlistModel = {} as TokenListModel;
			this.formTicketlistValid = false;
			await this.loadTicketList();
		}
		else
		{
			// or show error message
			ElMessage({message: "Beim Erstellen der neuen Ticketliste ist ein Fehler aufgetreten.",	type: 'error'});
		}
	
		this.loading = false;
    }

    private async deleteTicketlist(ticketlist : TokenListModel)
    {
        let ok = await ApiService.deleteTokenList(ticketlist);

        if (ok)
        {
			ElMessage({message: "Ticketliste wurde gelöscht.",	type: 'success'});
            await this.loadTicketList();
        }
        else
        {
            // or show error message
			ElMessage({message: "Ticketliste konnte nicht gelöscht werden.",	type: 'error'});
        }
    }

    private validateCustomerForm() : boolean
    {
        if (!this.customerModel || this.customerModel!.name.length <= 0) return false;

        return true;
    }

    private validateLocationForm() : boolean
    {
        if (!this.newLocationModel.name || this.newLocationModel.name.length <= 0) return false;

        return true;
    }

    private validateTicketlistForm() : boolean
    {
        // check, if name is set
        if (!this.newTicketlistModel.name || this.newTicketlistModel.name.length <= 0) return false;
        
        // check, if number of tickets is 1 or higher
        if (!this.numberOfTickets || this.numberOfTickets <= 0) return false;
        
        // check, if valid from date is set
        if (!this.newTicketlistModel.valid_from) return false;
        
        // check, if valid until date is set
        if (!this.newTicketlistModel.valid_until) return false;
        
        // check, if valid from date is in the past
        if (this.newTicketlistModel.valid_until <= new Date())
        {
			ElMessage({message: "Das Endedatum des Gültigkeitszeitraums liegt in der Vergangenheit.",	type: 'error'});
            return false;
        }

        // check, if valid from until is before valid from date
        if (this.newTicketlistModel.valid_until < this.newTicketlistModel.valid_from)
        {
			ElMessage({message: "Das Endedatum des Gültigkeitszeitraums liegt vor dem Anfangsdatum.",	type: 'error'});
            return false;
        }

        // check, if valid dates are colliding with another ticketlist for this customer
        if (this.tokenlistModelList && this.tokenlistModelList.length > 0)
        {
            for (let ticketlist : TokenListModel of this.tokenlistModelList)
            {
                let checkFrom : Date = new Date(0).setUTCSeconds(ticketlist.valid_from);
                let checkUntil : Date = new Date(0).setUTCSeconds(ticketlist.valid_until);

                if (!(this.newTicketlistModel.valid_from > checkUntil || this.newTicketlistModel.valid_until < checkFrom))
                {
                    ElMessage({message: "Der Gültigkeitszeitraum kollidiert mit einer anderen Ticketliste.",	type: 'error'});
                    return false;
                }
            }
        }

        return true;
    }

    private async showTicketsForList(ticketlist : TokenListModel)
    {
        this.showTickets = true;

        this.currentTicketlistModel = ticketlist;
        this.loadingTokens = true;
        this.tokenTableCount = await ApiService.getTableCount("token", `tokenlistid = ${ticketlist.tokenlistid.toString()}`);
        this.tokenModelList = await ApiService.getAllTokenForListView(ticketlist.tokenlistid.toString(), this.tokenPage.toString(), this.tokenCount.toString());
        this.loadingTokens = false;
    }

    private async onPageChange(page : any)
    {
        this.tokenPage = page-1;
        this.loadingTokens = true;
        this.tokenModelList = await ApiService.getAllTokenForListView(this.currentTicketlistModel.tokenlistid.toString(), this.tokenPage.toString(), this.tokenCount.toString());
        this.loadingTokens = false;
    }

	/*
    private async loadBanner()
    {
        if (this.customerModel)
        {
            this.bannerURL = `${ApiService.API_BASE_PATH}/customer/${this.customerModel.customerid.toString()}/banner/image`;
        }

        this.loading = false;
    }
	*/
    private async deleteBanner()
    {
        this.bannerURL = '';

        if (this.customerModel)
        {
            await ApiService.removeCustomerBanner(this.customerModel.customerid.toString());
        }
    }

    private async storeAttributeSelection()
    {
        if (this.customerModel)
        {
            // remove all available first
            for (let del of this.availableAttributes)
            {
                await ApiService.removeCustomerAttribute(this.customerModel.customerid.toString(), del.key.toString());
            }

            // insert selected attributes
            for (let ins of this.selectedAttributes)
            {
                let item : CustomerAttributeModel = {} as CustomerAttributeModel;
                item.customerid = this.customerModel.customerid;
                item.attributeid = ins;
                await ApiService.addCustomerAttribute(item);
            }
        }
    }

    private onLocationInput()
    {
        this.formLocationValid = this.validateLocationForm();
    }

    private onTicketlistInput(value : number)
    {
        if (value == null) 
        {
            value = 0;
            this.numberOfTickets = 0;
        }

        this.formTicketlistValid = this.validateTicketlistForm();
    }

    private onCustomerInput()
    {
        this.formCustomerValid = this.validateCustomerForm();
    }

    private onCustomerSaveClick()
    {
        if (this.customerModel)
        {
            this.saveCustomer(this.customerModel);
        }
    }

    private onLocationDeleteConfirm(action : string)
    {
        if (this.locationToDelete && action == 'confirm')
        {
            this.deleteLocation(this.locationToDelete);
        }
    }

    private onTicketListDeleteConfirm(action : string)
    {
        if (this.ticketListToDelete && action == 'confirm')
        {
            this.deleteTicketlist(this.ticketListToDelete);
        }
    }

    private onBannerDeleteConfirm(action : string)
    {
        if (action == 'confirm')
        {
            this.deleteBanner();
        }
    }

    private onShowTicketlist(ticketlist : TokenListModel)
    {
        this.showTicketsForList(ticketlist);
    }

    private onNewLocationClick()
    {
        this.createLocation();
    }

    private onLocationSaveClick(location : LocationModel)
    {
        this.updateLocation(location);
    }

    private onLocationEditClick(location : LocationModel)
    {
        this.$router.push(`/location/${location.locationid}`);
    }

    private onLocationDeleteClick(location : LocationModel)
    {
        this.locationToDelete = location;

        ElMessageBox.confirm(`Soll der Standort "${location.name}" wirklich gelöscht werden?`, 'Warning', 
        {
            type: 'warning',
            title: '',
            callback: this.onLocationDeleteConfirm,
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen'
        });
    }

    private onTicketlistDeleteClick(ticketlist : TokenListModel)
    {
        this.ticketListToDelete = ticketlist;

        ElMessageBox.confirm('Soll diese Ticketliste wirklich gelöscht werden?', 'Warning', 
        {
            type: 'warning',
            title: '',
            callback: this.onTicketListDeleteConfirm,
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen'
        });
    }

    private onBannerDeleteClick()
    {
        ElMessageBox.confirm(`Soll das Banner wirklich entfernt werden?`, 'Warning', 
        {
            type: 'warning',
            title: '',
            callback: this.onBannerDeleteConfirm,
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen'
        });
    }

    private onNewTicketlistClick()
    {
        this.createTicketlist();
    }

    private async readImage(file : any)
    {
        let data : Blob = file.raw;

        if (this.customerModel)
        {
            this.loading = true;
            await ApiService.setCustomerBanner(this.customerModel.customerid.toString(), file.raw);
            this.bannerURL = URL.createObjectURL(data);
            this.loading = false;
        }
    }

    private onImageSelect(file : any, fileList : FileList)
    {
        this.readImage(file);
    }

    private beforeImageUpload(file : File) 
    {
        let isJPG : boolean = (file.type === 'image/jpeg');
        let isPNG : boolean = (file.type === 'image/png');
        
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG && !isPNG) 
        {
			ElMessage({message: "Es können ausschließlich JPG oder PNG Dateien verwendet werden.",	type: 'error'});
        }

        if (!isLt2M) 
        {
			ElMessage({message: "Die Bilddatei darf nicht größer als 2 MB sein.",	type: 'error'});
        }

        return isJPG && isLt2M;
    }

    private onAttributeChange(x : any)
    {
        this.storeAttributeSelection();
    }
}

import { Vue, Options } from 'vue-class-component';
import SurveyService from '@/services/SurveyService';

@Options(
    {
        components:
        {
        },
    })

export default class StepAttribute extends Vue 
{
    private step: number = 0;
    private tokenId: string = '';
    private loading: boolean = true;
    private formValid: boolean = false;
    private questionnaireId: string = '0';
    private questionnaireModel: QuestionnaireModel | undefined;
    private participantModel: ParticipantModel | undefined;
    private categoryList: CategoryModel[] | undefined = [] as CategoryModel[];
    private attributeList: AttributeModel[] | undefined = [] as AttributeModel[];

    public mounted()
    {
        this.tokenId = this.$route.params.tokenid as string;
        this.questionnaireId = this.$route.params.questionnaireid as string;
        this.showFinishState();
    }

    private async showFinishState()
    {
        this.participantModel = await SurveyService.getParticipantForToken(this.tokenId);

        if (this.participantModel)
        {
            this.questionnaireModel = await SurveyService.getQuestionnaire(this.questionnaireId);

            if (this.questionnaireModel)
            {
                // load all categories for this questionnaire
                this.categoryList = await SurveyService.getAllCategoriesForQuestionnaire(this.questionnaireId);
                
                // set step to show progress
                if (this.categoryList)
                {
                    this.step = this.categoryList.length
                }

                // load attributes for feedback
                this.attributeList = await SurveyService.getAllAttribute();

                // load attribute choices
                if (this.attributeList)
                {
                    for (let attribute of this.attributeList)
                    {
                        // fetch if customer uses this attribute
                        let att : CustomerAttributeModel | undefined = await SurveyService.getCustomerAttribute(this.questionnaireModel.customerid.toString(), attribute.attributeid.toString());

                        if (att || attribute.isdefault)
                        {
                            attribute.inuse = true;
                        }

                        // load all choices for this attribute
                        attribute.choices = await SurveyService.getAllChoicesForAttribute(attribute.attributeid.toString());

                        // check if participant already made a choice for this attribute
                        let choice : ParticipantChoiceModel | undefined = await SurveyService.getChoiceForParticipant(this.participantModel.participantid.toString(), attribute.attributeid.toString());
                        
                        if (choice)
                        {
                            attribute.choiceid = choice.choiceid;
                        }
                    }
                }
            }
        }

        this.checkForm();

        this.loading = false;
    }

    private checkForm()
    {
        for (let attribute of this.attributeList!)
        {
            if (attribute.inuse && attribute.isrequired && (!attribute.choiceid || attribute.choiceid <= 0))
            {
                this.formValid = false;
                return;
            }
        }

        this.formValid = true;
    }

    private async setChoice(attribute : AttributeModel)
    {
        if (this.participantModel)
        {
            let choice : ParticipantChoiceModel | undefined = await SurveyService.getChoiceForParticipant(this.participantModel.participantid.toString(), attribute.attributeid.toString());

            if (choice)
            {
                await SurveyService.deleteChoiceForParticipant(choice.participantid.toString(), attribute.attributeid.toString(), choice.choiceid.toString());
            }

            await SurveyService.setChoiceForParticipant(this.participantModel.participantid.toString(), attribute.attributeid.toString(), attribute.choiceid.toString());
        }
    }

    public onFinish()
    {
        // mark this questionnaire as done
        SurveyService.finishToken(this.tokenId);

        // show final screen
        this.$router.push(`/step/${this.tokenId}/${this.questionnaireId}/done`);
    }

    public onChoiceChange(attribute : AttributeModel)
    {
        this.setChoice(attribute);
        this.checkForm();
    }
}

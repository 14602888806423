import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Plus, Check, Delete, CirclePlusFilled } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop,
        Check,
        Delete,
        Plus
    }
})

export default class QuestionnaireEdit extends Vue 
{
    private activeName: string = "1";
    private loading : boolean = true;
    private formValid : boolean = false;
    private hasMainPermission: boolean | undefined = false;
    private hasSubPermission: boolean | undefined = false;
    private deleteMode : boolean = false;
    private questId : string = "0";
    private questName : string = '';
    private filter : string = '';
    private page : number = 1;
    private rowsPerPage : number = 10;
    private displayList : QuestionModel[] | undefined = [] as QuestionModel[];
    private filteredList : QuestionModel[] | undefined = [] as QuestionModel[];
    private categoryModelList : CategoryModel[] = [];
    private questModel : QuestionnaireModel | undefined = {} as QuestionnaireModel;
    private questionModelList : QuestionModel[] | undefined = [] as QuestionModel[];
    private currentQuestion : QuestionModel | undefined;
    
    public async mounted()
    {
        // get permissions
        //this.hasMainPermission = await AuthService.hasPermission("7");
        //this.hasSubPermission = await AuthService.hasPermission("8");
        this.hasMainPermission = true;
        this.hasSubPermission = true;
        
        // get questionnaireid id from get request
        this.questId = this.$route.params.questionnaireid as string;

        // request questionnaire model from server
        this.questModel = await ApiService.getQuestionnaire(this.questId);

        if (!this.questModel)
        {
            this.$router.push('/questionnaire');
            return;
        }
        else
        {
            this.questName =  this.questModel.name;
            this.categoryModelList = await ApiService.getAllCategories() as CategoryModel[];
            this.questionModelList = await ApiService.getAllQuestionsForQuestionnaire(this.questId);
            this.filterList();
            this.showPage();
            this.loading = false;
        }
    }

    private async filterList()
    {   
        // reset filtered list
        this.filteredList = [] as QuestionModel[];

        // if a filter is set, add remaining records to filtered list
        if (this.filter.length > 0)
        {
            let i:number = 0;
            for (i = 0; i < this.questionModelList!.length; i++)
            {
                if (this.questionModelList![i].question.toLowerCase().includes(this.filter.toLowerCase()))
                {
                    this.filteredList.push(this.questionModelList![i]);
                }
            }
            this.page = 1;
        }
        else
        {
            this.filteredList = this.questionModelList;
        }

        return this.filter;
    }

    private async showPage()
    {
        // reset display list
        this.displayList = [] as QuestionModel[];

        // only add current page records to the display list
        let i:number = 0;
        if (this.questionModelList && this.filteredList)
        {
            for (i = 0; i < this.filteredList.length; i++)
            {
                if (i >= (this.page-1)*this.rowsPerPage && i < this.page*this.rowsPerPage)
                {
                    this.displayList.push(this.filteredList![i]);
                }
            }
        }

        return this.page;
    }

    private async loadQuestionList()
    {
        this.loading = true;
        this.questionModelList = await ApiService.getAllQuestionsForQuestionnaire(this.questId);
        this.loading = false;
    }

    private async deleteQuestion(question : QuestionModel)
    {
        let ok = await ApiService.deleteQuestion(question);

        if (ok)
        {
			ElMessage({message: "Frage wurde gelöscht.", type: 'success'});
            await this.loadQuestionList();
            await this.filterList();
            if (this.page > Math.ceil(this.filteredList!.length/this.rowsPerPage))
            {
                this.page = Math.ceil(this.filteredList!.length/this.rowsPerPage);
            }
            await this.showPage();
        }
        else
        {
            // or show error message
			ElMessage({message: "Frage konnte nicht gelöscht werden.",	type: 'error'});
        }
    }

    private async createQuestion()
    {
        let position : number = 1;
        let categoryid : number = this.categoryModelList[0].categoryid;
        let sourceQuestion : QuestionModel | undefined;

        // get last question row
        if (this.filteredList && this.filteredList!.length > 0)
        {
            position = this.filteredList![this.filteredList.length-1].position+1;
            categoryid = this.filteredList![this.filteredList.length-1].categoryid;
            sourceQuestion = this.filteredList![this.filteredList.length-1];
        }

        let newQuestion : QuestionModel | undefined = {} as QuestionModel;

        newQuestion.questionnaireid = this.questModel!.questionnaireid;
        newQuestion.position = position;
        newQuestion.categoryid = categoryid;
        newQuestion.question = "Frage";

        newQuestion = await ApiService.createQuestion(newQuestion);

        if (newQuestion)
        {
            await this.loadQuestionList();
            await this.filterList();
            this.page = Math.ceil((this.filteredList!.length)/this.rowsPerPage);
            await this.showPage();
        }
        else
        {
			ElMessage({message: "Frage konnte nicht erstellt werden.",	type: 'error'});
        }
    }

    private async saveQuestion(question : QuestionModel)
    {
        let ok : any = await ApiService.updateQuestion(question);

        if (ok)
        {
			ElMessage({message: "Änderungen wurden übernommen.", type: 'success'});
        }
        else
        {
			ElMessage({message: "Änderungen konnten nicht gespeichert werden.",	type: 'error'});
        }
    }

    private async wait(ms : number)
    {
        return new Promise(resolve => 
        {
            setTimeout(resolve, ms);
        });
    }

    private onQuestionDeleteClick(question : QuestionModel)
    {
        this.deleteQuestion(question);
    }

    private onQuestionSaveClick(question : QuestionModel)
    {
        this.saveQuestion(question);
    }

    private onNewQuestionClick()
    {
        if (this.displayList && this.displayList!.length > 0)
        {
            this.saveQuestion(this.displayList![this.displayList.length-1]);
        }
        this.filter = '';
        this.createQuestion();
    }

    private onPageChange(p : number)
    {
        this.page = p;
        this.showPage();
    }

    private onFilterChange()
    {
        this.filterList();
        this.showPage();
    }
}

import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Check, Checked, Delete, EditPen, CirclePlusFilled, Briefcase } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop,
        Check,
        EditPen,
        Delete,
        CirclePlusFilled,
        Briefcase
    },
})

export default class Customer extends Vue 
{
    private activeName: string = "1";   
    private customerModelList : CustomerModel[] = [];
    private tableData: object[] = [];
    private loading : boolean = true;
    private formValid : boolean = false;
    private hasPermission: boolean | undefined = false;
    private toDelete : CustomerModel | undefined;
    private newCustomerModel : CustomerModel = {} as CustomerModel;
    private rules =
    {
        name:
        [
            {
                required: true,
                message: "Bitte geben Sie eine Bezeichnung ein.",
                trigger: "blur"
            }
        ]
    };

    public async mounted()
    {
        // get permission
        //this.hasPermission = await AuthService.hasPermission("6");
        this.hasPermission = true;

        this.loadList();
    }

    private async loadList()
    {
        this.loading = true;
        this.customerModelList = await ApiService.getAllCustomers() as CustomerModel[];
        this.loading = false;
    }

    private async saveCustomer(customer : CustomerModel)
    {
        let ok : any = await ApiService.updateCustomer(customer);

        if (ok)
        {
            // show message on success
			ElMessage({message: "Änderungen wurden übernommen.",	type: 'success'});
        }
        else
        {
            // or show error message
			ElMessage({message: "Änderungen konnten nicht gespeichert werden.",	type: 'error'});
        }
    }

    private async createCustomer()
    {
        // send new customer model via request to API and await
        let customer = await ApiService.createCustomer(this.newCustomerModel);

        // check if successful
        if (customer)
        {
			ElMessage({message: "Neuer Mandant wurde erfolgreich erstellt.",	type: 'success'});
            this.activeName = "1";
            this.newCustomerModel = {} as CustomerModel;
            this.formValid = false;
            await this.loadList();
        }
        else
        {
            // or show error message
			ElMessage({message: "Beim Erstellen des neuen Mandanten ist ein Fehler aufgetreten.",	type: 'error'});
        }
    }

    private async deleteCustomer(customer : CustomerModel)
    {
        let ok = await ApiService.deleteCustomer(customer);

        if (ok)
        {
			ElMessage({message: "Mandant wurde erfolgreich gelöscht.",	type: 'success'});
            await this.loadList();
        }
        else
        {
            // or show error message
			ElMessage({message: "Mandant konnte nicht gelöscht werden.",	type: 'error'});
        }
    }

    private validateForm() : boolean
    {
        if (!this.newCustomerModel.name || this.newCustomerModel.name.length <= 0) return false;

        return true;
    }

    private onInput()
    {
        this.formValid = this.validateForm();
    }

    private onCustomerSaveClick(customer : CustomerModel)
    {
        this.saveCustomer(customer);
    }

    private onDeleteConfirm(action : string)
    {
        if (this.toDelete && action == 'confirm')
        {
            this.deleteCustomer(this.toDelete);
        }
    }

    private onCustomerDeleteClick(customer : CustomerModel)
    {
        this.toDelete = customer;

        ElMessageBox.confirm('Soll dieser Mandant wirklich gelöscht werden?', 'Warning', 
        {
            type: 'warning',
            title: '',
            callback: this.onDeleteConfirm,
            confirmButtonText: 'Löschen',
            cancelButtonText: 'Abbrechen'
        });
    }

    private onCustomerEditClick(customer : CustomerModel)
    {
        this.$router.push(`/customer/${customer.customerid}`);
    }

    private onNewCustomerClick()
    {
        this.createCustomer();
    }
    
}

import { Vue, Options } from 'vue-class-component';
import { Check, EditPen, Close, Search } from '@element-plus/icons-vue';
import ApiService from '@/services/ApiService';

@Options(
{
    props:
    {
        block:
        {
            type: Object,
            required: false
        }
    },
    components:
    {
        Search,
        EditPen,
        Check,
        Close
    }
})


export default class CategoryItem extends Vue 
{
    public block : SummaryBlock;

    private loading : boolean = true;
    private editing : boolean = false;
    private noteCallToAction : NoteModel | undefined = {} as NoteModel;
    private noteRiskAssessment : NoteModel | undefined = {} as NoteModel;
    private noteRecommendation : NoteModel | undefined = {} as NoteModel;

    private dashColors = 
    [
        { color: null, percentage: 10 },
        { color: null, percentage: 20 },
        { color: null, percentage: 30 },
        { color: null, percentage: 40 },
        { color: null, percentage: 50 },
        { color: null, percentage: 60 },
        { color: null, percentage: 70 },
        { color: null, percentage: 80 },
        { color: null, percentage: 90 },
        { color: null, percentage: 100 }
    ];

    public async mounted()
    {
        if (this.block)
        {
            await this.loadDashColors();
            await this.loadNotes();
        }
    }

    private async loadDashColors()
    {
        let colors : string[] | undefined = await ApiService.getDashColors();
         
        for (let c : number = 0; c < colors.length; c++)
        {
            this.dashColors[c].color = colors[c].value;
        }
    }

    private async loadNotes()
    {
        this.loading = true;

        this.noteCallToAction = await ApiService.getNote(this.block.questionnaireid, this.block.categoryid, 1);
        if (!this.noteCallToAction)
        {
            this.noteCallToAction = {} as NoteModel;
        }

        this.noteRiskAssessment = await ApiService.getNote(this.block.questionnaireid, this.block.categoryid, 2);
        if (!this.noteRiskAssessment)
        {
            this.noteRiskAssessment = {} as NoteModel;
        }

        this.noteRecommendation = await ApiService.getNote(this.block.questionnaireid, this.block.categoryid, 3);
        if (!this.noteRecommendation)
        {
            this.noteRecommendation = {} as NoteModel;
        }

        this.loading = false;
    }

    private onBlockClick()
    {
        this.$router.push(`/report/${this.block.questionnaireid}/detail/${this.block.categoryid}`);
    }

    private onEditClick()
    {
        this.editing = true;
    }

    private async onSaveClick()
    {
        if (this.noteCallToAction.questionnaireid > 0)
        {
            await ApiService.updateNote(this.noteCallToAction);
        }
        else
        {
            this.noteCallToAction.questionnaireid = this.block.questionnaireid;
            this.noteCallToAction.categoryid = this.block.categoryid;
            this.noteCallToAction.notetype = 1;
            this.noteCallToAction = await ApiService.createNote(this.noteCallToAction);
        }

        if (this.noteRiskAssessment.questionnaireid > 0)
        {
            await ApiService.updateNote(this.noteRiskAssessment);
        }
        else
        {
            this.noteRiskAssessment.questionnaireid = this.block.questionnaireid;
            this.noteRiskAssessment.categoryid = this.block.categoryid;
            this.noteRiskAssessment.notetype = 2;
            this.noteRiskAssessment = await ApiService.createNote(this.noteRiskAssessment);
        }

        if (this.noteRecommendation.questionnaireid > 0)
        {
            await ApiService.updateNote(this.noteRecommendation);
        }
        else
        {
            this.noteRecommendation.questionnaireid = this.block.questionnaireid;
            this.noteRecommendation.categoryid = this.block.categoryid;
            this.noteRecommendation.notetype = 3;
            this.noteRecommendation = await ApiService.createNote(this.noteRecommendation);
        }

        this.editing = false;
    }

    private async onCancelClick()
    {
        await this.loadNotes();
        this.editing = false;
    }
}

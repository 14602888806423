import { Vue, Options } from 'vue-class-component';
import { List, TrendCharts, Filter, Search } from '@element-plus/icons-vue';
import ApiService from '@/services/ApiService';

interface FieldMap
{
    displayName : string;
    internalName : string;
}

@Options(
{
    props:
    {
        filter:
        {
            type: Object,
            required: true
        }
    },
    components:
    {
        Search,
        TrendCharts,
        List,
        Filter
    }
})


export default class AnswersByAttribute extends Vue 
{
    public  filter : FilterModel;

    private loading : boolean = true;
    private initialized : boolean = false;
    private activeName : string = "1";
    private dataTable : any[] | undefined;
    private currentCustomer : number = 0;
    private currentLocation : number = 0;
    private currentDepartment : number = 0;
    private currentCategory : number = 0;
    private currentQuestion : number = 0;
    private choiceList : ChoiceModel[] | undefined;
    private choices : string[] = [];

    public async mounted()
    {
        this.$emit('report-answers-ready', this.refresh);
    }
    
    public async refresh()
    {
        if (this.filter.selectedQuestions.length > 0)
        {
            await this.buildTable();
        }
    }

    private async buildTable()
    {
        // show loading indicator
        this.loading = true;

        // reset iterations
        this.currentCategory = 0;
        this.currentQuestion = 0;

        // reset choice selection
        this.choices = [] as string[];

        // fetch choice from attribute selection
        for (let att : number of this.filter.selectedAttributes)
        {
            this.choiceList = await ApiService.getAllChoicesForAttribute(att.toString());
            for (let choice : ChoiceModel of this.choiceList) 
            {
                this.choices.push(choice.choice);
            }
        }

        // get table data
        this.dataTable = await ApiService.getAnswersReportTable(this.filter);
        
        // reset loading indicator
        this.loading = false;
    }
}

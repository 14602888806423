import { Vue, Options } from 'vue-class-component';
import SurveyService from '@/services/SurveyService';

@Options(
{
    components:
    {
    },
})

export default class StepSelect extends Vue 
{
    private formValid: boolean = false;
    private loading: boolean = true;
    private locationId: string = 'Bitte wählen...';
    private departmentId: string = 'Bitte wählen...';
    private questModel: QuestionnaireModel | undefined = {} as QuestionnaireModel;
    private tokenModel: TokenModel | undefined = {} as TokenModel;
    private tokenListModel: TokenListModel | undefined = {} as TokenListModel;
    private customerModel: CustomerModel | undefined = {} as CustomerModel;
    private locationList: LocationModel[] | undefined = [] as LocationModel[];
    private departmentList: DepartmentModel[] | undefined = [] as DepartmentModel[];
    private bannerURL : string | undefined = '';

    public async mounted()
    {
        let tokenid: string = this.$route.params.tokenid as string;

        if (tokenid)
        {
            await this.loadLocations(tokenid);
            /*            
            if (this.customerModel && this.customerModel.banner_size > 0)
            {
                this.bannerURL = `${ApiService.API_BASE_PATH}/customer/${this.customerModel.customerid.toString()}/banner/image`;
                setTimeout(this.loadComplete, 1000);
            }
            else
            {
                this.loading = false;
            }
            */
        }
        else
        {
            this.$router.push('/start');
        }
    }

    private checkForm()
    {
        this.formValid = false;

        if (this.locationList && this.locationList.length > 0)
        {
            if (this.departmentList && this.departmentList.length > 0)
            {
                if (parseInt(this.locationId) > 0 && parseInt(this.departmentId) > 0)
                {
                    this.formValid = true;
                }
            }
        }
    }

    private async loadComplete()
    {
        this.loading = false;
    }

    private async loadLocations(tokenid: string)
    {
        this.loading = true;

        // get token from server
        this.tokenModel = await SurveyService.getToken(tokenid);

        if (this.tokenModel)
        {
            // get tokenlist from server
            this.tokenListModel = await SurveyService.getTokenList(this.tokenModel.tokenlistid.toString());

            if (this.tokenListModel)
            {
                // get company from server
                this.customerModel = await SurveyService.getCustomer(this.tokenListModel.customerid.toString());

                if (this.customerModel)
                {
                    // get locations for customer
                    this.locationList = await SurveyService.getAllLocationsByCustomerId(this.customerModel.customerid.toString());
                }
            }
        }
        else
        {
            this.$router.push('/start');
        }

        this.loading = false;
    }

    private async loadDepartments(locationid: string)
    {
        if (this.locationId)
        {
            this.departmentList = await SurveyService.getAllDepartmentsByLocationId(this.locationId);

            if (this.departmentList!.length <= 0)
            {
                this.departmentId = 'Bitte wählen...';
            }

            if (this.departmentList!.length == 1)
            {
                this.departmentId = this.departmentList![0].departmentid.toString();
            }
        }
    }

    private async loadQuestionnaireAndStart()
    {
        if (this.tokenModel && this.tokenListModel && parseInt(this.locationId) > 0 && parseInt(this.departmentId) > 0)
        {
            this.questModel = await SurveyService.getQuestionnaireByLoactionAndDepartment(this.locationId, this.departmentId);

            if (this.questModel && !this.tokenModel.isdone)
            {
                // load existing participant or create a new one
                let participantModel : ParticipantModel | undefined;
                participantModel = await SurveyService.getParticipantForToken(this.tokenModel.tokenid.toString());

                if (!participantModel)
                {
                    participantModel = {} as ParticipantModel;
                    participantModel.questionnaireid = this.questModel.questionnaireid;
                    participantModel.tokenid = this.tokenModel!.tokenid;
                    participantModel.datelogin = new Date();
                    await SurveyService.createParticipant(participantModel);
                }
                else
                {
                    participantModel.datelogin = new Date();
                    await SurveyService.updateParticipant(participantModel);
                }

                this.$router.push(`/step/${this.tokenModel!.tokenid.toString()}/${this.questModel.questionnaireid}`);
            }
            else
            {
                this.$router.push('/step/expired');
            }
        }
    }

    private async onLocationChange(locationid: string)
    {
        this.locationId = locationid;
        this.departmentId = 'Bitte wählen...';
        await this.loadDepartments(this.locationId);

        this.checkForm();
    }

    private onDepartmentChange(departmentid: string)
    {
        this.checkForm();
    }

    private onStartClick()
    {
        this.loadQuestionnaireAndStart();
    }
}

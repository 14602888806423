import { Vue, Options } from 'vue-class-component';
import { ElMessage, ElMessageBox } from 'element-plus';
import { Ship } from '@element-plus/icons-vue';
import SimulationService from '@/services/SimulationService';

interface TimeLineEntry
{
    content : string;
    timestamp : string;
    finished : boolean;
}

@Options(
{
    components:
    {
        Ship
    },
})

export default class Simulation extends Vue 
{
    private loading : boolean = true;
    private running : boolean = false;
    private formValid : boolean = false;
    private step : number = 0;
    private simModel : SimulationModel = {} as SimulationModel;
    private results : string = "";
    private percentage : number = 0;
    private timeline : TimeLineEntry[] = [] as TimeLineEntry[];

    private rules =
    {
        name:
        [
            {
                required: true,
                message: 'Bitte geben Sie einen Namen für die Rolle ein.',
                trigger: 'blur'
            }
        ],
    };

    public async mounted()
    {
        this.loading = true;
    }

    private async buildSim()
    {
        if (this.simModel!.input)
        {
            this.simModel = await SimulationService.build(this.simModel!.input);

            if (this.simModel!.simulationid > 0)
            {
                this.stepSim(this);
            }
        }

        this.loading = false;
    }

    private async stepSim(self : any)
    {

        if (self.simModel)
        {
            self.results = await SimulationService.step(self.simModel!.simulationid);
            
            if (self.results)
            {
                let item : TimeLineEntry = {} as TimeLineEntry;
                item.content = self.results["token"];
                item.timestamp = new Intl.DateTimeFormat('de-DE', {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(new Date(0).setUTCSeconds(self.results["timestamp"])) + " - Fragen beantwortet: " + Object.keys(self.results["questions"]).length;
                item.finished = self.results["finished"];
                self.timeline.push(item);

                if (self.results)
                {
                    setTimeout(self.stepSim, 10, self);
                }
            }
        }
    
        self.percentage = Math.round(self.step / self.simModel.participant_count * 100);
        
        self.step++;
    }

    private validateForm() : boolean
    {
        if (!this.simModel.input || this.simModel.input.length <= 0) return false;

        return true;
    }

    private onInput()
    {
        this.formValid = this.validateForm();
    }

    private onStartClick()
    {
        this.running = true;
        this.loading = true;
        this.buildSim();
    }
}

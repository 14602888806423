import axios from 'axios';

export default class AuthService
{
    static AUTH_PATH: string = `${process.env.VUE_APP_REST_PATH}/auth`;
    static USER_PATH: string = `${process.env.VUE_APP_REST_API_PATH}/userinfo`;
    static PART_PATH: string = `${process.env.VUE_APP_REST_URI}/participate`;
    static PERM_PATH: string = `${process.env.VUE_APP_REST_PATH}/auth/access`;

    public static async login(email: string, passwd: string)
    {
        let result : UserModel | undefined;

        try
        {
            var form = new FormData();
            form.set('username', email);
            form.set('password', passwd);

            let response = await axios.post(`${this.AUTH_PATH}/login`,
            {
                username: email,
                password: passwd
            });

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    result = response.data as UserModel;
                    localStorage.setItem("auth", "true");
                    localStorage.setItem("user", (result.firstname + " " + result.lastname));
                }
            }
        }
        catch (error)
        {
            //ErrorService.process(error);
        }

        return result;
    }

    public static async getUser()
    {
        try
        {
            let response = await axios.get(`${AuthService.USER_PATH}`);

            if (response && response.status === 200)
            {
                if (response.data)
                {
                    return response.data as UserModel;
                }
            }
        }
        catch (error)
        {
            throw(error);
        }
    }

    public static async participate(ticket: string)
    {
        let result : boolean = false;

        try
        {
			let params = 
			{
				token: ticket
			}

            let response = await axios.post(`${this.AUTH_PATH}/participate`, params);

            if (response && response.status === 200)
            {
                result = true;
                    //localStorage.setItem("auth", "true");
            }
        }
        catch (error)
        {
            //ErrorService.process(error);
        }

        return result;
    }

    public static logout()
    {
        // remove auth flag and user from client storage
        localStorage.removeItem('auth');
        localStorage.removeItem('user');
    };

    public static async isLoggedIn()
    {
        return (localStorage.getItem("auth") && localStorage.getItem("auth") == "true") as boolean;
    }

    public static async hasPermission(permissionid : string)
    {
        try
        {
            let response = await axios.get(`${AuthService.PERM_PATH}/${permissionid}`);

            if (response && response.status === 200)
            {
                return true;
            }
        }
        catch (error)
        {
            return false;
        }
    }
}

import { Vue, Options } from 'vue-class-component';
import { List, TrendCharts, Filter, Search } from '@element-plus/icons-vue';
import ApiService from '@/services/ApiService';

interface FieldMap
{
    displayName : string;
    internalName : string;
    isdefault : boolean;
    isorder : boolean;
}

interface CalcMap
{
    displayName : string;
    internalName : string;
    sql : string;
    isdefault : boolean;
}

interface TransferItem
{
    key : number;
    label : string;
    disabled : boolean;
}

@Options(
{
    props:
    {
        filter:
        {
            type: Object,
            required: true
        }
    },
    components:
    {
        Search,
        TrendCharts,
        List,
        Filter
    }
})


export default class ReportTable extends Vue 
{
    public  filter : FilterModel;
    private initialized : boolean = false;
    private loading : boolean = true;
    private activeName : string = "0";
    private fieldClause : string = "" as string;
    private whereClause : string = "";
    private groupClause : string = "";
    private orderClause : string = "";
    private fieldMaps : FieldMap[] = [] as FieldMap[];
    private countMaps : CalcMap[] = [] as CalcMap[];
    private sumMaps : CalcMap[] = [] as CalcMap[];
    private averageMaps : CalcMap[] = [] as CalcMap[];
    private dataTable : ReportTable[] | undefined;
    private availableColumns : TransferItem[] = [] as TransferItem[];
    private selectedColumns : number[] = [] as number[];
    private availableOrders : TransferItem[] = [] as TransferItem[];
    private selectedOrders : number[] = [] as number[];
    private availableCounts : TransferItem[] = [] as TransferItem[];
    private selectedCounts : number[] = [] as number[];
    private availableSums : TransferItem[] = [] as TransferItem[];
    private selectedSums : number[] = [] as number[];

    public async mounted()
    {
        if (!this.initialized)
        {
            await this.setupConfig();
        }

        this.$emit('report-ready', this.refresh);
    }
    
    private async setupConfig()
    {
        // setup field maps
        let map : FieldMap = {} as FieldMap;
        map.displayName = "Fragebogen";
        map.internalName = "questionnaire_name";
        map.isdefault = true;
        map.isorder = true;
        this.fieldMaps.push(map);
        
        map = {} as FieldMap;
        map.displayName = "Mandant";
        map.internalName = "customer_name";
        map.isdefault = true;
        map.isorder = true;
        this.fieldMaps.push(map);

        map = {} as FieldMap;
        map.displayName = "Standort";
        map.internalName = "location_name";
        map.isdefault = true;
        this.fieldMaps.push(map);

        map = {} as FieldMap;
        map.displayName = "Abteilung";
        map.internalName = "department_name";
        map.isdefault = true;
        this.fieldMaps.push(map);

        map = {} as FieldMap;
        map.displayName = "Kategorie";
        map.internalName = "category_name";
        map.isdefault = false;
        this.fieldMaps.push(map);

        map = {} as FieldMap;
        map.displayName = "Frage";
        map.internalName = "question";
        map.isdefault = false;
        this.fieldMaps.push(map);

        map = {} as FieldMap;
        map.displayName = "Antwort";
        map.internalName = "answer";
        map.isdefault = false;
        this.fieldMaps.push(map);

        map = {} as FieldMap;
        map.displayName = "Ticketliste";
        map.internalName = "tokenlist_name";
        map.isdefault = false;
        this.fieldMaps.push(map);

        map = {} as FieldMap;
        map.displayName = "Ticket";
        map.internalName = "token";
        map.isdefault = false;
        this.fieldMaps.push(map);

        map = {} as FieldMap;
        map.displayName = "Teilnehmereigenschaft";
        map.internalName = "attribute_name";
        map.isdefault = false;
        this.fieldMaps.push(map);

        map = {} as FieldMap;
        map.displayName = "Eigenschaftswert";
        map.internalName = "choice";
        map.isdefault = false;
        this.fieldMaps.push(map);

        // setup calc maps
        map = {} as CalcMap;
        map.displayName = "Fragen";
        map.internalName = "question_count";
        map.sql = "COUNT(DISTINCT question)";
        map.isdefault = false;
        this.countMaps.push(map);
        
        map = {} as CalcMap;
        map.displayName = "Antworten";
        map.internalName = "answer_count";
        map.sql = "COUNT(DISTINCT answerid)";
        map.isdefault = false;
        this.countMaps.push(map);

        map = {} as CalcMap;
        map.displayName = "Eigenschaftswert";
        map.internalName = "choice_count";
        map.sql = "COUNT(choiceid)";
        map.isdefault = false;
        this.countMaps.push(map);

        let i : number = 0;
        for (let field of this.fieldMaps)
        {
            // add field items
            let fieldItem : TransferItem = {} as TransferItem;
            fieldItem.key = i;
            fieldItem.label = field.displayName;
            fieldItem.disabled = false;
            this.availableColumns.push(fieldItem);
            if (field.isdefault)
            {
                this.selectedColumns.push(fieldItem.key);
            }
            
            // add order items
            let orderItem : TransferItem = {} as TransferItem;
            orderItem.key = i;
            orderItem.label = field.displayName;
            orderItem.disabled = false;
            if (field.isdefault)
            {
                this.availableOrders.push(orderItem);
            }
            if (field.isdefault && field.isorder)
            {
                this.selectedOrders.push(orderItem.key);
            }

            i++;
        }

        let c : number = 0;
        for (let count of this.countMaps)
        {
            // add count items
            let countItem : TransferItem = {} as TransferItem;
            countItem.key = c;
            countItem.label = count.displayName;
            countItem.disabled = false;
            this.availableCounts.push(countItem);
            if (count.isdefault)
            {
                this.selectedCounts.push(countItem.key);
            }
            
            c++;
        }

        this.initialized = true;
    }

    public async refresh()
    {
        await this.buildTable();
    }

    private async buildTable()
    {
        // show loading indicator
        this.loading = true;
        
        // reset sql command
        this.fieldClause = "";
        this.whereClause = " true ";
        this.groupClause = "";
        this.orderClause = "";

        // build field list (automatically group by list)
        for (let c : number of this.selectedColumns)
        {
            if (this.fieldClause.length > 0) this.fieldClause += ",";
            this.fieldClause += this.fieldMaps[c].internalName;

            if (this.groupClause.length > 0) this.groupClause += ",";
            this.groupClause += this.fieldMaps[c].internalName;
        }
        
        for (let c : number of this.selectedCounts)
        {
            if (this.fieldClause.length > 0) this.fieldClause += ",";
            this.fieldClause += this.countMaps[c].sql + " AS " + this.countMaps[c].internalName;
        }

        // build order by list
        for (let c : number of this.selectedOrders)
        {
            if (this.orderClause.length > 0) this.orderClause += ",";
            this.orderClause += this.fieldMaps[c].internalName;
        }

        this.dataTable = await ApiService.getReportTable(this.fieldClause, this.whereClause, this.groupClause, this.orderClause, this.filter);

        this.loading = false;
    }

    private async onColumnChange()
    {
        let i : number = 0;

        this.availableOrders = [] as TransferItem[];
        this.selectedOrders = [] as number[];

        for (let col of this.selectedColumns)
        {
            let orderItem : TransferItem = {} as TransferItem;
            orderItem.key = col;
            orderItem.label = this.availableColumns[col].label;
            orderItem.disabled = false;
            this.availableOrders.push(orderItem);

            if (this.selectedOrders.length <= 0)
            {
                this.selectedOrders.push(orderItem.key)
            }
        }

        await this.buildTable();
    }

    private async onOrderChange()
    {
        await this.buildTable();
    }

    private async onCountChange()
    {
        await this.buildTable();
    }

    private async onSumChange()
    {
        await this.buildTable();
    }
}

import { Vue, Options } from 'vue-class-component';
import { Download, Search } from '@element-plus/icons-vue';
import NavBarTop from '@/components/NavBarTop/NavBarTop.vue';
import CategoryItem from '@/components/CategoryItem/CategoryItem.vue';
import AuthService from '@/services/AuthService';
import ApiService from '@/services/ApiService';

@Options(
{
    components:
    {
        NavBarTop,
        CategoryItem,
		Download,
        Search
    },
})

export default class ReportSummary extends Vue 
{
    private loading : boolean = true;
    private downloading : boolean = false;
    private questId : string = "0";
    private questName : string = "";
    private questModel : QuestionnaireModel | undefined;
    private hasPermission : boolean | undefined = false;
    private participantCount : number = 0;
    private tokenCount : number = 0;
    private doneCount : number = 0;
    private questionTotal : number = 0;
    private reportData : ReportSummaryView[] | undefined = [] as ReportSummaryView[];
    private blockList : SummaryBlock[] = [] as SummaryBlock[];

    public async mounted()
    {
        // get permission
        //this.hasPermission = await AuthService.hasPermission("4");
        this.hasPermission = true;
        
        // get questionnaireid id from get request
        this.questId = this.$route.params.questionnaireid as string;

        // load questionnaire
        this.questModel = await ApiService.getQuestionnaire(this.questId);

        if (this.questModel)
        {
            this.questName = this.questModel.name;
        }

        // get number of participants for this questionnaire
        let list : ParticipantModel[] | undefined = await ApiService.getParticipantsForQuestionnaire(this.questId) as ParticipantModel[];

        if (list)
        {
            // store count
            this.participantCount = list.length;

            // get token
            let token : TokenModel | undefined = await ApiService.getToken(list[0].tokenid.toString());

            if (token)
            {
                // get tokenlist
                let tokenList : TokenListModel | undefined = await ApiService.getTokenList(token.tokenlistid.toString());

                if (tokenList)
                {
                    let tokens : TokenModel[] | undefined = await ApiService.getAllTokensByTokenList(tokenList.tokenlistid.toString());

                    if (tokens)
                    {
                        for (let t of tokens)
                        {
                            this.tokenCount++;
                            if (t.isdone)
                            {
                                this.doneCount++;
                            }
                        }
                    }
                }
            }
        }

        // get detail list and process for display
        await this.loadAndProcessReport();

        // set loading indicator
        this.loading = false;
    }

    private async loadAndProcessReport()
    {
        let totalRisk : number = 0;

        // get report data
        this.reportData = await ApiService.getReportSummary(this.questId);
        
        if (this.reportData)
        {
            let categoryid : number = 0;
            let catNum : number = 0;
            let questionid : number = 0;
            let blockRisk : number = 0;
            let invertedRisk : number = 0;
            let block = {} as SummaryBlock;

            for (let entry of this.reportData)
            {
                if (entry.categoryid != categoryid)
                {
                    // reset the risk for the new block
                    blockRisk = 0;

                    // increment category number
                    catNum++;

                    // create summary block
                    block = {} as SummaryBlock;
                    block.no = catNum;
                    block.questionnaireid = this.questModel.questionnaireid;
                    block.categoryid = entry.categoryid;
                    block.category = entry.category_name;
                    block.questionCount = 0;
                    block.answerCount = 0;
                    block.noteCount = 0;
                    block.evalPercentage = 0;
                    this.blockList.push(block);
                    
                    // remember category
                    categoryid = entry.categoryid;
                }

                if (questionid != entry.questionid)
                {
                    block.questionCount++;
                    this.questionTotal++;
                    questionid = entry.questionid;
                }

                block.answerCount += entry.answer_count;
                block.noteCount += entry.note_count;
                
                blockRisk += (entry.risk * entry.answer_count * entry.weight);

                // calc total risk
                totalRisk = (entry.max_risk * entry.max_weight * this.participantCount) * block.questionCount;

                if (entry.invert)
                {
                    invertedRisk = totalRisk - blockRisk;
                    block.evalPercentage = Math.round((invertedRisk / totalRisk) * 100);
                }
                else
                {
                    block.evalPercentage = Math.round((blockRisk / totalRisk) * 100);
                }
            }
        }
    }

    private onBlockClick(categoryid : number)
    {
        this.$router.push(`/report/${this.questId}/detail/${categoryid}`);
    }

    private async onDownloadClick()
    {
        this.downloading = true;

        let data : Blob | undefined = await ApiService.downloadExcel(this.questId);

		if (data)
        {
            let blob : Blob = new Blob([data], {type: 'application/vnd.ms-excel'});
            let fileURL : string = window.URL.createObjectURL(blob);
            let fileLink : HTMLAnchorElement = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.download = 'export.xlsx';
            document.body.appendChild(fileLink);

            fileLink.click();

            URL.revokeObjectURL(fileLink.href);
        }

        this.downloading = false;
    }
}

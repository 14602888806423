import { Vue, Options } from 'vue-class-component';
import SurveyService from '@/services/SurveyService';

interface UpdateStack
{
    answerid: number;
    note: string;
}

@Options(
{
    components:
    {
    },
})

export default class StepQuestion extends Vue 
{
    private step: number = 0;
    private noteid: number = 0;
    private showNextStep: boolean = false;
    private showPrevStep: boolean = false;
    private loading: boolean = true;
    private nextButtonLabel: string = 'Fortfahren';
    private prevButtonLabel: string = 'Zurück';
    private textBoxLength: number = 300;
    private tokenId: string = '';
    private questionnaireId: string = '0';
    private questionnaireModel: QuestionnaireModel | undefined;
    private participantModel: ParticipantModel | undefined;
    private categoryModel: CategoryModel | undefined;
    private categoryList: CategoryModel[] | undefined = [] as CategoryModel[];
    private questionList: QuestionModel[] | undefined;
    private displayQuestions: QuestionModel[] = [] as QuestionModel[];
    private updateStack : UpdateStack[] = [] as UpdateStack[];

    public async mounted()
    {
        this.tokenId = this.$route.params.tokenid as string;

        if (this.tokenId)
        {
            this.questionnaireId = this.$route.params.questionnaireid as string;

            if (this.questionnaireId)
            {
                // load questions for this questionnaire
                this.loadQuestionsAndShowStep();

                // check, if next button is active
                this.checkNext();

                // start update notes routine
                setInterval(this.updateNotes, 5000);
            }
        }
    }

    private checkNext()
    {
        for (let question of this.displayQuestions)
        {
            if (!question.answer || question.answer.length <= 0)
            {
                this.showNextStep = false;
                return;
            }
        }

        this.showNextStep = true;
    }

    private async showQuestions()
    {
        this.loading = true;

        if (this.categoryList && this.categoryList.length > 0)
        {
            if (!this.questionList)
            {
                this.questionList = await SurveyService.getAllQuestionsForQuestionnaire(this.questionnaireId);
            }

            if (this.questionList)
            {
                this.displayQuestions = [] as QuestionModel[];

                for (let question of this.questionList)
                {
                    if (question.categoryid == this.categoryList![this.step].categoryid)
                    {
                        // load current category
                        this.categoryModel = await SurveyService.getCategory(question.categoryid.toString());
                        
                        if (this.categoryModel)
                        {
                            // load all options for this category
                            this.categoryModel.options = await SurveyService.getAllOptionsForCategory(question.categoryid.toString());

                            let answerModel: AnswerModel | undefined = await SurveyService.getAnswerForParticipant(this.participantModel!.participantid.toString(), question.questionid.toString());

                            if (answerModel)
                            {
                                question.answerid = answerModel.answerid;
                                question.answer = answerModel.answer;
                                question.note = answerModel.note;
                            }

                            // add question to display list
                            this.displayQuestions.push(question);
                        }
                    }
                }
            }

            if (this.step == this.categoryList!.length - 1)
            {
                this.nextButtonLabel = "Fragebogen abschließen";
            }
            else
            {
                this.nextButtonLabel = "Fortfahren";
            }
        }

        this.checkNext();

        this.loading = false;
    }

    private async loadQuestionsAndShowStep()
    {
        this.participantModel = await SurveyService.getParticipantForToken(this.tokenId);

        if (this.participantModel)
        {
            this.questionnaireModel = await SurveyService.getQuestionnaire(this.questionnaireId);

            if (this.questionnaireModel)
            {
                // load all categories for this questionnaire
                this.categoryList = await SurveyService.getAllCategoriesForQuestionnaire(this.questionnaireId);

                if (this.categoryList && this.categoryList.length > 0)
                {
                    // set current step according to answered questions
                    this.step = 0;
                    
                    // get next unanswered 
                    let nextCategory : CategoryModel = await SurveyService.getNextCategory(this.participantModel.participantid.toString()) as CategoryModel;

                    if (nextCategory)
                    {
                        for (let category of this.categoryList)
                        {
                            if (category.categoryid == nextCategory!.categoryid)
                            {
                                break;
                            }
                            this.step++;
                        }

                        if (this.step > 0)
                        {
                            this.showPrevStep = true;
                        }

                        // load questions for the current step
                        await this.showQuestions();
                    }
                    else
                    {
                        // go to last step
                        this.$router.push(`/step/${this.tokenId}/${this.questionnaireId}/close`);
                    }
                }
            }
        }
    }

    private async updateNotes()
    {
        // abort, if nothing to update
        if (this.updateStack.length <= 0) return;

        // loop through all updates and send notes
        for (let update of this.updateStack)
        {
            let answerModel : AnswerModel | undefined = {} as AnswerModel;
            answerModel.answerid = update.answerid;
            answerModel.note = update.note;
            await SurveyService.setAnswerNote(answerModel);
        }

        // clear update stack
        this.updateStack = [] as UpdateStack[];
    }

    private onNoteChange(question : QuestionModel, note : string)
    {
        if (question.answerid)
        {
            if (this.updateStack.length > 0)
            {
                for (let update of this.updateStack)
                {
                    if (update.answerid === question.answerid)
                    {
                        update.note = note;
                    }
                }
            }
            else
            {
                let update : UpdateStack = {} as UpdateStack;
                update.answerid = question.answerid;
                update.note = note;
                this.updateStack.push(update);
            }
        }
    }

    private async selectOption(question : QuestionModel, option : OptionModel)
    {
        // send answer to server
        let answerModel : AnswerModel | undefined = {} as AnswerModel;

        answerModel.participantid = this.participantModel!.participantid;
        answerModel.questionnaireid = parseInt(this.questionnaireId);
        answerModel.questionid = question.questionid;
        answerModel.questionid = question.questionid;
        answerModel.optionid = option.optionid;
        answerModel.answer = question.answer;
        answerModel.note = question.note;

        answerModel = await SurveyService.createAnswer(answerModel);

        if (answerModel)
        {
            question.answerid = answerModel.answerid;
        }
    }

    private onOptionSelect(question: QuestionModel)
    {
        if (this.categoryModel)
        {
            for (let option of this.categoryModel.options!)
            {
                if (option.option === question.answer)
                {
                    this.selectOption(question, option);
                    break;
                }
            }
        }

        this.checkNext();
    }

    private onNextStepClick()
    {
        if (this.step < this.categoryList!.length - 1)
        {
            this.step++;
        }
        else
        {
            // go to last step
            this.$router.push(`/step/${this.tokenId}/${this.questionnaireId}/close`);
        }

        if (this.step < this.categoryList!.length)
        {
            this.showQuestions();
        }

        this.showPrevStep = (this.step > 0 && this.step < this.categoryList!.length);
    }

    private onPrevStepClick()
    {
        if (this.step > 0)
        {
            this.step--;
        }

        if (this.step < this.categoryList!.length)
        {
            this.showQuestions();
        }

        this.showPrevStep = (this.step > 0 && this.step < this.categoryList!.length);
    }
}
